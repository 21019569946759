import { Controller } from '@hotwired/stimulus';
import {
  csrfToken
} from '../../application/javascripts/helpers/api_requests_helper';

export default class extends Controller {
  static targets = ['errors']
  static values = {
    url: String,
    key: String
  }
  static modalTarget = 'modal'

  connect () {
    console.log(this.element.action)
  }

  async submit (e) {
    // TODO: Show the loading wheel
    this.errorsTarget.innerHTML = ''
    const allFields = this.element.querySelectorAll('.hexo-field')
    allFields.forEach((field) => {
      field.classList.remove('hexo-field--error')
    })
    this._showLoading()

    const response = await this._sendRequest();

    if (response.status === 201) {
      this._hideLoading();
      this._closeModal();
      location.reload()
    } else {
      this._hideLoading();
      this.errorsTarget.append(this._buildErrors(response.errors))
    }
  }

  _buildErrors (errors) {
    const list = document.createElement('ul')

    for (const key in errors) {
      const data = errors[key];
      const input = this._getInputElement(key)

      input.classList.add('hexo-field--error')

      data.forEach((error) => {
        const str = key.charAt(0).toUpperCase() + key.slice(1) + ' ' + error
        const el = document.createElement('li')
        el.innerHTML = str

        list.append(el)
      })
    }

    return list;
  }

  _showLoading () {
    this._modalBody().classList.add('hexo-modal__box--loading')
  }

  _hideLoading () {
    setTimeout(() => {
      this._modalBody().classList.remove('hexo-modal__box--loading')
    }, 1000)
  }

  _modalBody () {
    return this.element.closest('.hexo-modal__box')
  }

  _getInputElement (name) {
    const inputName = (name === 'amount') ? 'money' : name
    const input = this.element.querySelector('input[name=' + inputName + ']')

    return input.closest('.hexo-field')
  }

  _getFormData () {
    const fields = this.element.querySelectorAll('input');
    const data = {};
    
    [...fields].forEach((i) => {
      data[i.name] = i.value 
    });

    return { [this.keyValue]: data }
  }

  async _sendRequest () {
    const formData = this._getFormData();

    try {
      const result = await fetch(this.urlValue, {
        headers: {
          'X-CSRF-Token': csrfToken(),
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(formData)
      })

      const data = await result.json()

      return data
    } catch (e) {
      console.log(e)
      return undefined
    }
  }

  _closeModal () {
    const modal = this.element.closest(this._targetString(this.constructor.modalTarget))

    if (modal) {
      modal[this.constructor.modalTarget].closeModal()
    }
  }

  _targetString (target) {
    return '*[data-controller="' + target + '"]'
  }
}
