import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["section"]

  initialize() {
    this.hide()
  }

  toggle(event) {
    event.preventDefault()
    if (this.sectionTarget.style.display === 'none') {
      this.show()
    } else {
      this.hide()
    }
  }

  show() {
    this.sectionTarget.style.display = 'initial'
  }

  hide() {
    this.sectionTarget.style.display = 'none'
  }
}
