export const rulesMatrix = () => {
  return {
    authorization_count: authorizationCount(),
    bin: bin(),
    bin_country: binCountry(),
    billing_address_country: billingAddressCountry(),
    capture_count: captureCount(),
    chargeback_ratio_by_amount: chargebackRatioByAmount(),
    chargeback_ratio_by_number: chargebackRatioByNumber(),
    chargeback_count: chargebackCount(),
    credit_count: creditCount(),
    ip_country: ipCountry(),
    pan_name: panName(),
    payment_transaction_count: paymentTransactionCount(),
    payout_transaction_count: payoutTransactionCount(),
    refund_transaction_count: refundTransactionCount(),
    total_chargeback_transactions_amount: totalChargebackTransactionsAmount(),
    total_failed_payment_transactions_count: totalFailedPaymentTransactionsCount(),
    total_successful_payment_transactions_amount: totalSuccessfulPaymentTransactionsAmount(),
    total_successful_payment_transactions_count: totalSuccessfulPaymentTransactionsCount(),
    transaction_amount: transactionAmount(),
    transaction_currency: transactionCurrency(),
    transaction_type: transactionType(),
    unique_bin_count: uniqueBinCount(),
    unique_ip_address_count: uniqueIPAddressCount(),
    unique_pan_count: uniquePANCount(),
    unique_pan_name_count: uniquePANNameCount(),
    unique_billing_address_count: uniqueBillingAddressCount(),
    unique_customer_name_count: uniqueCustomerNameCount(),
    unique_device_id_count: uniqueDeviceIdCount(),
    unique_email_count: uniqueEmailCount(),
    unique_phone_number_count: uniquePhoneNumberCount(),
    void_transaction_count: voidTransactionCount()
  }
}

export const buildSelect = (name, label, options) => {
  const el = document.createElement('div')
  el.classList.add('hexo-column')
  el.classList.add('hexo-column__t--12')
  el.dataset.controller = 'fields--select'
  el.setAttribute('data-fields--select-target', 'parent')
  el.dataset.fieldName = name

  switch (name) {
    case 'time_range':
      el.setAttribute('data-fields--select-change-target-value', 'hex-protect--rule-condition')
      el.setAttribute('data-fields--select-change-method-value', 'timeRangeChange')
      break
    case 'comparison':
      el.setAttribute('data-fields--select-change-target-value', 'hex-protect--rule-condition')
      el.setAttribute('data-fields--select-change-method-value', 'comparisonChange')
  }

  const parent = document.createElement('div')
  parent.classList.add('hexo-field')
  parent.classList.add('hexo-field__no-margin')
  parent.dataset.action = 'click->fields--select#open'
  parent.setAttribute('data-fields--select-target', 'field')

  const select = document.createElement('select')
  select.name = name
  select.classList.add('hexo-field__input')
  select.setAttribute('data-fields--select-target', 'select')

  options.forEach((o) => {
    const option = document.createElement('option')
    option.value = o.key
    option.innerHTML = o.value

    select.append(option)
  })

  parent.append(buildLabel(label))
  parent.append(select)

  el.append(parent)

  return el
}

export const buildText = (name, label) => {
  const el = document.createElement('div')
  el.classList.add('hexo-column')
  el.classList.add('hexo-column__t--12')
  el.dataset.fieldName = name

  const parent = document.createElement('div')
  parent.classList.add('hexo-field')
  parent.classList.add('hexo-field__no-margin')

  const field = document.createElement('input')
  field.type = 'text'
  field.name = name
  field.classList.add('hexo-field__input')

  parent.append(buildLabel(label))
  parent.append(field)

  el.append(parent)

  return el
}

const buildLabel = (text) => {
  const el = document.createElement('label')
  el.classList.add('hexo-field__label')
  el.innerHTML = text

  return el
}

const authorizationCount = () => {
  return {
    name: 'Authorization transaction count',
    fields: [
      defaultComparisonField(),
      defaultValueField(),
      filterTransactionDataField(),
      defaultTimeField(),
      defaultTimeRangeField()
    ]
  }
}

const bin = () => {
  return {
    name: 'BIN',
    fields: [
      setComparisonField(),
      setListField()
    ]
  }
}

const binCountry = () => {
  return {
    name: 'BIN country',
    fields: [
      countryComparisonField(),
      defaultValueField(),
      setListField()
    ]
  }
}

const billingAddressCountry = () => {
  return {
    name: 'Billing address country',
    fields: [
      countryComparisonField(),
      defaultValueField(),
      setListField()
    ]
  }
}

const captureCount = () => {
  return {
    name: 'Capture transaction count',
    fields: [
      defaultComparisonField(),
      defaultValueField(),
      filterTransactionDataField(),
      defaultTimeField(),
      defaultTimeRangeField()
    ]
  }
}

const chargebackRatioByAmount = () => {
  return {
    name: 'Chargeback ratio by amount',
    fields: [
      defaultComparisonField(),
      defaultValueField(),
      filterCardBrandField(),
      defaultTimeField(),
      defaultTimeRangeField()
    ]
  }
}

const chargebackRatioByNumber = () => {
  return {
    name: 'Chargeback ratio by number',
    fields: [
      defaultComparisonField(),
      defaultValueField(),
      filterCardBrandField(),
      defaultTimeField(),
      defaultTimeRangeField()
    ]
  }
}

const chargebackCount = () => {
  return {
    name: 'Chargeback transaction count',
    fields: [
      defaultComparisonField(),
      defaultValueField(),
      filterTransactionDataField(),
      defaultTimeField(),
      defaultTimeRangeField()
    ]
  }
}

const creditCount = () => {
  return {
    name: 'Credit transaction count',
    fields: [
      defaultComparisonField(),
      defaultValueField(),
      filterTransactionDataField(),
      defaultTimeField(),
      defaultTimeRangeField()
    ]
  }
}

const ipCountry = () => {
  return {
    name: 'IP country',
    fields: [
      countryComparisonField(),
      defaultValueField(),
      setListField()
    ]
  }
}

const panName = () => {
  return {
    name: 'PAN name',
    fields: [
      setComparisonField(),
      setListField()
    ]
  }
}

const paymentTransactionCount = () => {
  return {
    name: 'Payment transaction count',
    fields: [
      defaultComparisonField(),
      defaultValueField(),
      filterTransactionDataField(),
      defaultTimeField(),
      defaultTimeRangeField()
    ]
  }
}

const payoutTransactionCount = () => {
  return {
    name: 'Payout transaction count',
    fields: [
      defaultComparisonField(),
      defaultValueField(),
      filterTransactionDataField(),
      defaultTimeField(),
      defaultTimeRangeField()
    ]
  }
}

const refundTransactionCount = () => {
  return {
    name: 'Refund transaction count',
    fields: [
      defaultComparisonField(),
      defaultValueField(),
      filterTransactionDataField(),
      defaultTimeField(),
      defaultTimeRangeField()
    ]
  }
}

const totalChargebackTransactionsAmount = () => {
  return {
    name: 'Total chargeback transactions amount',
    fields: [
      defaultComparisonField(),
      defaultValueField(),
      defaultValueTypeField(),
      filterTransactionDataField(),
      defaultTimeField(),
      defaultTimeRangeField()
    ]
  }
}

const totalFailedPaymentTransactionsCount = () => {
  return {
    name: 'Total failed payment transactions count',
    fields: [
      defaultComparisonField(),
      defaultValueField(),
      filterTransactionDataField(),
      defaultTimeField(),
      defaultTimeRangeField()
    ]
  }
}

const totalSuccessfulPaymentTransactionsAmount = () => {
  return {
    name: 'Total successful payment transactions amount',
    fields: [
      defaultComparisonField(),
      defaultValueField(),
      defaultValueTypeField(),
      filterTransactionDataField(),
      defaultTimeField(),
      defaultTimeRangeField()
    ]
  }
}

const totalSuccessfulPaymentTransactionsCount = () => {
  return {
    name: 'Total successful payment transactions count',
    fields: [
      defaultComparisonField(),
      defaultValueField(),
      filterTransactionDataField(),
      defaultTimeField(),
      defaultTimeRangeField()
    ]
  }
}

const transactionAmount = () => {
  return {
    name: 'Transaction amount',
    fields: [
      defaultComparisonField(),
      defaultValueField(),
      defaultValueTypeField(),
      transactionTypeFilter()
    ]
  }
}

const transactionCurrency = () => {
  return {
    name: 'Transaction currency',
    fields: [
      countryComparisonField(),
      defaultValueField(),
      setListField()
    ]
  }
}

const transactionType = () => {
  return {
    name: 'Transaction type',
    fields: [
      countryComparisonField(),
      defaultValueField(),
      setListField()
    ]
  }
}

const uniqueBinCount = () => {
  return {
    name: "Unique BIN count",
    fields: [
      defaultComparisonField(),
      defaultValueField(),
      filterTransactionDataField(),
      defaultTimeField(),
      defaultTimeRangeField()
    ]
  }
}

const uniqueIPAddressCount = () => {
  return {
    name: "Unique IP address count",
    fields: [
      defaultComparisonField(),
      defaultValueField(),
      filterTransactionDataFieldForIP(),
      defaultTimeField(),
      defaultTimeRangeField()
    ]
  }
}

const uniquePANCount = () => {
  return {
    name: "Unique PAN count",
    fields: [
      defaultComparisonField(),
      defaultValueField(),
      filterTransactionDataFieldForPAN(),
      defaultTimeField(),
      defaultTimeRangeField()
    ]
  }
}

const uniquePANNameCount = () => {
  return {
    name: "Unique PAN name count",
    fields: [
      defaultComparisonField(),
      defaultValueField(),
      filterTransactionDataFieldForPANName(),
      defaultTimeField(),
      defaultTimeRangeField()
    ]
  }
}

const uniqueBillingAddressCount = () => {
  return {
    name: "Unique billing address count",
    fields: [
      defaultComparisonField(),
      defaultValueField(),
      filterTransactionDataFieldForBillingAddress(),
      defaultTimeField(),
      defaultTimeRangeField()
    ]
  }
}

const uniqueCustomerNameCount = () => {
  return {
    name: "Unique customer name count",
    fields: [
      defaultComparisonField(),
      defaultValueField(),
      filterTransactionDataFieldForCustomerName(),
      defaultTimeField(),
      defaultTimeRangeField()
    ]
  }
}

const uniqueDeviceIdCount = () => {
  return {
    name: "Unique device ID count",
    fields: [
      defaultComparisonField(),
      defaultValueField(),
      filterTransactionDataFieldForDeviceId(),
      defaultTimeField(),
      defaultTimeRangeField()
    ]
  }
}

const uniqueEmailCount = () => {
  return {
    name: "Unique email count",
    fields: [
      defaultComparisonField(),
      defaultValueField(),
      filterTransactionDataFieldForEmail(),
      defaultTimeField(),
      defaultTimeRangeField()
    ]
  }
}

const uniquePhoneNumberCount = () => {
  return {
    name: "Unique phone number count",
    fields: [
      defaultComparisonField(),
      defaultValueField(),
      filterTransactionDataFieldForPhoneNumber(),
      defaultTimeField(),
      defaultTimeRangeField()
    ]
  }
}

const voidTransactionCount = () => {
  return {
    name: 'Void transaction count',
    fields: [
      defaultComparisonField(),
      defaultValueField(),
      filterTransactionDataField(),
      defaultTimeField(),
      defaultTimeRangeField()
    ]
  }
}

const optionObject = (key, value) => {
  return {
    key: key,
    value: value
  }
}

const defaultComparisonField = () => {
  return {
    name: 'comparison',
    label: 'is',
    type: 'select',
    options: [
      optionObject('not equal', 'Not Equal'),
      optionObject('equal', 'Equal'),
      optionObject('less than', 'Less Than'),
      optionObject('less or equal than', 'Less or Equal Than'),
      optionObject('more than', 'More Than'),
      optionObject('more or equal than', 'More or Equal Than')
    ]
  }
}

const countryComparisonField = () => {
  return {
    name: 'comparison',
    label: 'is',
    type: 'select',
    options: [
      optionObject('not equal', 'not equal'),
      optionObject('equal', 'equal'),
      optionObject('is not in', 'not in'),
      optionObject('is in', 'in')
    ]
  }
}

const setComparisonField = () => {
  return {
    name: 'comparison',
    label: 'is',
    type: 'select',
    options: [
      optionObject('is not in', 'not in'),
      optionObject('is in', 'in')
    ]
  }
}

const defaultValueField = () => {
  return {
    name: 'value',
    label: 'to',
    type: 'text'
  }
}

const defaultValueTypeField = () => {
  return {
    name: 'value_type',
    currencies: true,
    label: 'Currency',
    type: 'select',
    options: []
  }
}

const filterTransactionDataField = () => {
  return {
    name: 'filter',
    label: 'For',
    type: 'select',
    options: [
      optionObject('- none -', '- None -'),
      optionObject('IP address', 'IP Address'),
      optionObject('Device ID', 'Device ID'),
      optionObject('Phone number', 'Phone Number'),
      optionObject('Email', 'Email'),
      optionObject('Billing address', 'Billing Address'),
      optionObject('Customer name', 'Customer Name'),
      optionObject('PAN name', 'PAN Name'),
      optionObject('PAN', 'PAN'),
    ]
  }
}

const filterTransactionDataFieldForIP = () => {
  return {
    name: 'filter',
    label: 'For',
    type: 'select',
    options: [
      optionObject('- none -', '- None -'),
      optionObject('Device ID', 'Device ID'),
      optionObject('Phone number', 'Phone Number'),
      optionObject('Email', 'Email'),
      optionObject('Billing address', 'Billing Address'),
      optionObject('Customer name', 'Customer Name'),
      optionObject('PAN name', 'PAN Name'),
      optionObject('PAN', 'PAN'),
    ]
  }
}

const filterTransactionDataFieldForPAN = () => {
  return {
    name: 'filter',
    label: 'For',
    type: 'select',
    options: [
      optionObject('- none -', '- None -'),
      optionObject('IP address', 'IP Address'),
      optionObject('Device ID', 'Device ID'),
      optionObject('Phone number', 'Phone Number'),
      optionObject('Email', 'Email'),
      optionObject('Billing address', 'Billing Address'),
      optionObject('Customer name', 'Customer Name'),
      optionObject('PAN name', 'PAN Name')
    ]
  }
}

const filterTransactionDataFieldForPANName = () => {
  return {
    name: 'filter',
    label: 'For',
    type: 'select',
    options: [
      optionObject('- none -', '- None -'),
      optionObject('IP address', 'IP Address'),
      optionObject('Device ID', 'Device ID'),
      optionObject('Phone number', 'Phone Number'),
      optionObject('Email', 'Email'),
      optionObject('Billing address', 'Billing Address'),
      optionObject('Customer name', 'Customer Name'),
      optionObject('PAN name', 'PAN Name')
    ]
  }
}

const filterTransactionDataFieldForBillingAddress = () => {
  return {
    name: 'filter',
    label: 'For',
    type: 'select',
    options: [
      optionObject('- none -', '- None -'),
      optionObject('IP address', 'IP Address'),
      optionObject('Device ID', 'Device ID'),
      optionObject('Phone number', 'Phone Number'),
      optionObject('Email', 'Email'),
      optionObject('Customer name', 'Customer Name'),
      optionObject('PAN name', 'PAN Name'),
      optionObject('PAN', 'PAN'),
    ]
  }
}

const filterTransactionDataFieldForCustomerName = () => {
  return {
    name: 'filter',
    label: 'For',
    type: 'select',
    options: [
      optionObject('- none -', '- None -'),
      optionObject('IP address', 'IP Address'),
      optionObject('Device ID', 'Device ID'),
      optionObject('Phone number', 'Phone Number'),
      optionObject('Email', 'Email'),
      optionObject('Billing address', 'Billing Address'),
      optionObject('PAN name', 'PAN Name'),
      optionObject('PAN', 'PAN'),
    ]
  }
}

const filterTransactionDataFieldForDeviceId = () => {
  return {
    name: 'filter',
    label: 'For',
    type: 'select',
    options: [
      optionObject('- none -', '- None -'),
      optionObject('IP address', 'IP Address'),
      optionObject('Phone number', 'Phone Number'),
      optionObject('Email', 'Email'),
      optionObject('Billing address', 'Billing Address'),
      optionObject('PAN name', 'PAN Name'),
      optionObject('PAN', 'PAN'),
    ]
  }
}

const filterTransactionDataFieldForEmail = () => {
  return {
    name: 'filter',
    label: 'For',
    type: 'select',
    options: [
      optionObject('- none -', '- None -'),
      optionObject('IP address', 'IP Address'),
      optionObject('Device ID', 'Device ID'),
      optionObject('Phone number', 'Phone Number'),
      optionObject('Billing address', 'Billing Address'),
      optionObject('Customer name', 'Customer Name'),
      optionObject('PAN name', 'PAN Name'),
      optionObject('PAN', 'PAN'),
    ]
  }
}

const filterTransactionDataFieldForPhoneNumber = () => {
  return {
    name: 'filter',
    label: 'For',
    type: 'select',
    options: [
      optionObject('- none -', '- None -'),
      optionObject('IP address', 'IP Address'),
      optionObject('Device ID', 'Device ID'),
      optionObject('Email', 'Email'),
      optionObject('Billing address', 'Billing Address'),
      optionObject('Customer name', 'Customer Name'),
      optionObject('PAN name', 'PAN Name'),
      optionObject('PAN', 'PAN'),
    ]
  }
}

const filterCardBrandField = () => {
  return {
    name: 'filter',
    label: 'For',
    type: 'select',
    options: [
      optionObject('All cards', 'All Cards'),
      optionObject('Mastercard', 'Mastercard'),
      optionObject('VISA', 'VISA'),
    ]
  }
}

const transactionTypeFilter = () => {
  return {
    name: 'filter',
    label: 'For',
    type: 'select',
    options: [
      optionObject('Payout transaction','Payout Transaction'),
      optionObject('Credit transaction','Credt Transaction'),
      optionObject('Chargeback transaction','Chargeback Transaction'),
      optionObject('Void transaction','Void Transaction'),
      optionObject('Refund transaction','Refund Transaction'),
      optionObject('Payment transaction','Payment Transaction'),
      optionObject('Capture transaction','Capture transaction'),
      optionObject('Authorization transaction','Authorization transaction'),
    ]
  }
}

const defaultTimeField = () => {
  return {
    name: 'time',
    label: 'in',
    type: 'text'
  }
}

const defaultTimeRangeField = () => {
  return {
    name: 'time_range',
    label: 'Time Range',
    type: 'select',
    options: [
      optionObject('hours', 'Hours'),
      optionObject('days', 'Days'),
      optionObject('current month', 'Current Month')
    ]
  }
}

const setListField = () => {
  return {
    name: 'set_name',
    label: 'Set',
    type: 'select',
    options: [
    ]
  }
}
