import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    id: Number,
    inAmount: Number,
    outAmount: Number,
    refundAmount: Number,
    inText: String,
    outText: String
  };
  static targets = ['paymentAmount', 'payoutAmount', 'incomeValue', 'refundValue'];

  connect () {
    this.paymentAmountTarget.innerHTML = this._converPenceToPounds(this.inAmountValue)
    this.payoutAmountTarget.innerHTML = this._converPenceToPounds(this.outAmountValue + this.refundAmountValue)

    const total = this.inAmountValue + this.outAmountValue + this.refundAmountValue
    this.paymentAmountTarget.style.minWidth = this._percentageOf(this.inAmountValue, total).toFixed() + '%'

    this.paymentAmountTarget.title = this._buildTitle(
      this._inText(),
      this._formatValue(this.inAmountValue / 100)
    )
    this.payoutAmountTarget.title = this._buildTitle(
      this._outText(),
      this._formatValue((this.outAmountValue + this.refundAmountValue) / 100)
    )

    this.incomeValueTarget.innerHTML = this._formatValue(
      (
        this.inAmountValue - (
          this.outAmountValue +
          this.refundAmountValue
        )
      ) / 100
    )
    this.refundValueTarget.innerHTML = this._formatValue(
      this.refundAmountValue / 100
    )
  }

  _percentageOf (x, y) {
    return (x / y) * 100
  }

  _formatValue (number) {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'EUR' }).format(number)
  }

  _converPenceToPounds (number) {
    const n = number / 100

    if (n >= 1000000) {
      return (n / 1000000).toFixed(1) + ' M'
    } else if (n >= 1000) {
      return (n / 1000).toFixed(1) + ' K'
    } else {
      return n
    }
  }

  _buildTitle (label, value) {
    return label + ': ' + value
  }

  _inText () {
    return (( this.inTextValue ) ? this.inTextValue : 'Payments' )
  }

  _outText () {
    return (( this.outTextValue ) ? this.outTextValue : 'Payouts' )
  }
}
