import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['parent', 'value', 'field', 'message'];
  static values = {
    successText: String,
    failedText: String
  }
  static colorMapping = {
    success: 'success',
    error: 'danger'
  }

  connect () {
    this.parentTarget.append(this._createTextArea())
  }

  copy (e) {
    e.preventDefault();

    this.fieldTarget.focus();
    this.fieldTarget.select();

    try {
      document.execCommand('copy')
      this._setMessage('success')
    } catch (e) {
      this._setMessage('error')
    }
  }

  _createTextArea () {
    let el = document.createElement('textarea')
    el.style.top = "0"
    el.style.left = "0"
    el.style.opacity = "0"
    el.style.position = "fixed"
    el.dataset.copyTextTarget = 'field'
    el.value = this.valueTarget.innerHTML

    return el
  }

  _setMessage (style) {
    let message = document.createElement('span')
    message.classList.add('text')
    message.classList.add("text--" + this._textColor(style))
    message.classList.add("text--bold")
    message.innerHTML = this._message(style)

    this.messageTarget.innerHTML = ''
    this.messageTarget.append(message)
  }

  _textColor (style) {
    const color = this.constructor.colorMapping[style]
    return (color) ? color : 'primary'
  }

  _message (style) {
    if (style == 'success') {
      return ((this.successTextValue) ? this.successTextValue : 'Copied!')
    } else {
      return ((this.failedTextValue) ? this.failedTextValue : 'Copied Failed!')
    }
  }
}
