import { Controller } from '@hotwired/stimulus';
import Chart from 'chart.js/auto';
import stc from 'string-to-color';

import {
  generateTransactionPeriodWithObject,
  getDataPeriod,
  baseChartConfig,
  percentageToolTip,
  percentageTicks
} from '../../application/javascripts/helpers/live_dashboard_helper';

export default class extends Controller {
  static targets = ['successRateGraph', 'successRate', 'filters'];

  connect() {
    this.dataPeriod = getDataPeriod();

    this.transactionsObserver = new MutationObserver(
      this._updateChart.bind(this),
    );
    this.transactionsObserver.observe(this.successRateTarget.parentNode, {
      childList: true,
    });

    this.transactionsObserver.observe(this.filtersTarget, {
      childList: true,
      attributes: true
    });

    if (this.successRateTarget.children[0].value) {
      this.generateChart(this._chartConfig());
    }
  }

  generateChart(config) {
    this.chart = new Chart(this.successRateGraphTarget, config);
  }

  _chartLabels() {
    return JSON.parse(this.successRateTarget.children[0].value).labels;
  }

  _chartData() {
    return JSON.parse(this.successRateTarget.children[0].value).data;
  }

  _getFilters () {
    let filters = null;

    if (this.filtersTarget.value) {
      filters = JSON.parse(this.filtersTarget.value)
    }

    return filters;
  }

  _getShopIds() {
    let shopIds = [];
    let filters = this._getFilters();

    if (filters) {
      shopIds = filters.shops.map((item) => parseInt(item))
    }

    return shopIds;
  }

  _uniqueGateways() {
    const blankObject = {
      total: 0,
      success: 0
    }

    this.GATEWAYS =  this._chartData()
      .map((datum) => datum.gateway)
      .reduce(
        (gateways, key) => ({
          ...gateways,
          [key]: generateTransactionPeriodWithObject(this.dataPeriod, blankObject)
        }),
        {},
      );
  }

  _generateDatasets() {
    this._uniqueGateways();

    const shopIds = this._getShopIds();

    this._chartData().forEach((datum) => {
      if (shopIds.includes(datum.shop)) {
        this.GATEWAYS[datum.gateway].forEach((_entry, index) => {
          this.GATEWAYS[datum.gateway][index][datum.type] += parseFloat(datum.dataset[index]);
        });
      }
    });

    return Object.entries(this.GATEWAYS).map(
      ([gateway, gatewayTransactions]) => {
        const color = stc(gateway);

        return {
          label: gateway,
          data: gatewayTransactions.map((data) => {
            if (data.total > 0) {
              return ((data.success / data.total) * 100).toFixed(2)
            }
            return 0.0;
          }),
          borderColor: color,
          backgroundColor: color,
          color: 'rgb(137, 139, 150)',
          tension: 0.1
        };
      },
    );
  }

  _updateChart() {
    if (
      this.hasSuccessRateTarget &&
      this.successRateTarget.children[0].value &&
      this.dataPeriod
    ) {

      this._generateDatasets().forEach((dataset) => {
        this._updateDataset(dataset.label, dataset.data);
      })

      this._updateLabels();

      this.chart?.update();
    }
  }

  _updateDataset(key, data) {
    const dataset = this.chart.data.datasets.find((dataset) => {
      return dataset.label === key;
    });

    dataset.data = data;
  }

  _updateLabels() {
    if (this.chart) {
      this.chart.data.labels = this._chartLabels();
    }
  }

  _chartConfig() {
    let config = baseChartConfig('Success Rates', 'line');

    config.data = {
      labels: this._chartLabels(),
      datasets: this._generateDatasets(),
    }

    config.options.plugins.tooltip = percentageToolTip();
    config.options.scales.y.ticks = percentageTicks()

    return config;
  }

}
