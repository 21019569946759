export const showNotice = (message) => {
  const target = findAlertHolder()
  const text = createText()
  const wrapper = createWrapper()
  const notice = createNotice()

  text.innerHTML = message

  wrapper.append(text)
  notice.append(wrapper)
  target.append(notice)
}

const createNotice = () => {
  const notice = document.createElement('div')
  notice.classList.add('hexo-alert')
  notice.classList.add('hexo-alert--success')
  notice.setAttribute('data-controller', 'alert')
  notice.setAttribute('data-alert-timed-value', true)
  notice.setAttribute('data-alert-closeable-value', true)
  notice.setAttribute('data-alert-target', 'parent')
  return notice
}

const createWrapper = () => {
  const wrapper = document.createElement('div')
  wrapper.classList.add('hexo-alert__wrapper')
  wrapper.setAttribute('data-alert-target', 'wrapper')
  return wrapper
}

const createText = () => {
  const text = document.createElement('div')
  text.classList.add('hexo-alert__text')
  return text
}

const findAlertHolder = () => {
  return document.querySelector('.hexo-alerts-container')
}
