import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input', 'field']
  static values = {
    show: String
  }

  static hiddenClass = 'hidden'

  connect () {
    this._setInputAction()
    this.change()
  }

  change () {
    const match = this._inputValueMatch()

    this.fieldTargets.forEach(field => {
      if (match) { this._enableField(field) }
      else { this._disableField(field) }
    })
  }

  _setInputAction () {
    if (this.hasInputTarget) {
      this.inputTarget.dataset.action = this._inputAction()
    }
  }

  _inputAction () {
    return 'change->' + this.identifier + '#change'
  }

  _inputValueMatch () {
    return (
      this.hasInputTarget &&
      this.hasShowValue &&
      this.inputTarget.value == this.showValue
    )
  }

  _disableField (field) {
    field.disabled = true
    field.closest('.control-group').classList.add(this.constructor.hiddenClass)
  }

  _enableField (field) {
    field.disabled = false
    field.closest('.control-group').classList.remove(this.constructor.hiddenClass)
  }
}
