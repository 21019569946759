import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['content']
  static values = { key: String, small: Boolean }

  connect () {
    this.element[this.identifier] = this
  }

  openModal (e) {
    if (e) {
      e.preventDefault
    }

    document.body.append(this._buildModal())
  }

  _buildModal () {
    const modal = document.createElement('div')
    modal.dataset.controller = 'modal'
    modal.dataset.modalTarget = 'parent'
    modal.dataset.globalModalKey = this.keyValue
    modal.dataset.modalDestroyOnCloseValue = true
    modal.dataset.modalOpenOnCreateValue = true

    if (this.hasSmallValue && this.smallValue === true) {
      modal.dataset.modalSmallValue = true
    }

    const content = document.createElement('div')
    content.style = 'display: none;'
    content.dataset.modalTarget = 'content'
    content.innerHTML = this.contentTarget.innerHTML

    modal.append(content)
    return modal
  }
}
