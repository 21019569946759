import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['message', 'options', 'filterData'];
  static openClass = 'filters__select--options-open'

  connect () {
    this._generateDisplay();
    this._populateFilterData();
  }

  toggle () {
    this.optionsTarget.classList.toggle(this.constructor.openClass);
    this._generateDisplay();
    this._populateFilterData();
  }

  selectAll () {
    this.optionsTarget.querySelectorAll('input[type=checkbox]').forEach((option) => {
      option.checked = true;
    })

    this.toggle()
  }

  _populateFilterData () {
    let data = []

    this._selectedOptions().forEach((option) => {
      data.push(option.value)
    })

    this.filterDataTarget.value = JSON.stringify(data)
  }

  _generateDisplay () {
    const allOptions = this.optionsTarget.querySelectorAll('input[type=checkbox]').length
    const options = this._selectedOptions();

    let message = this.messageTarget.dataset.defaultAll

    if (options.length < allOptions) {
      message = '<span class="badge">'
      message += options.length
      message += '</span>selected'
    }
    else if (options.length === 1) {
      message = options[0].nextElementSibling.innerHTML;
    }

    this.messageTarget.innerHTML = message;
  }

  _selectedOptions () {
    return this.optionsTarget.querySelectorAll('input[type=checkbox]:checked');
  }
}
