import { Controller } from '@hotwired/stimulus';
import {
  csrfToken
} from '../application/javascripts/helpers/api_requests_helper';
import {
  showNotice
} from '../application/javascripts/helpers/notice_helper';
import {
  closeModal
} from '../application/javascripts/helpers/modal_helper';

export default class extends Controller {
  static targets = ['email', 'transactions', 'message']

  static reportFormId = 'reports-form'
  static defaultErrorMessage = 'An error occured. Please try again later.'

  connect () {
    this.element[this.identifier] = this

    if (!this.hasMessageTarget) {
      const el = document.createElement('div')
      el.setAttribute('data-' + this.identifier + '-target', 'message')

      this.element.append(el)
    }
  }

  async export () {
    const formData = this._collectReportData()

    if (formData) {
      formData['export_email'] = this._collectEmailAddress()

      if (this._checkIncludeTransactions()) {
        formData['include_transactions'] = true
      }

      const response = await this._sendExportRequest(formData)
      
      if (response) {
        if (response.status === 200) {
          this._renderNotice(response.data.message)
          this._closeModal()
        } else {
          this._showErrorMessage(response.data.message)
        }
      } else {
        this._showErrorMessage(this.constructor.defaultErrorMessage)
      }
    }
  }

  _collectReportData () {
    const form = document.querySelector('#' + this.constructor.reportFormId)

    if (form) {
      const formData = {}
      const baseFields = document.querySelectorAll('#' + this.constructor.reportFormId + ' > input')
      const reportFields = form.querySelectorAll('*[data-report-fields] input')
      const fields = Array.from(baseFields).concat(Array.from(reportFields))

      fields.forEach((field) => {
        if (field.name) {
          formData[field.name] = field.value
        }
      })

      return formData
    } else {
      return undefined
    }
  }

  _collectEmailAddress () {
    if (this.hasEmailTarget) {
      return this.emailTarget.value
    } else {
      return null
    }
  }

  _checkIncludeTransactions () {
    return this.hasTransactionsTarget && this.transactionsTarget.checked
  }

  async _sendExportRequest (data) {
    try {
      const result = await fetch('/merchant/report/export?' + new URLSearchParams(data).toString(), {
        headers: {
          'X-CSRF-Token': csrfToken(),
        },
        method: 'GET',
        credentials: 'include'
      })

      const response = await result.json()

      return { status: result.status, data: response }
    } catch (e) {
      return undefined
    }
  }

  _renderNotice (message) {
    showNotice(message)
  }

  _showErrorMessage (message) {
    if (this.hasMessageTarget) {
      this.messageTarget.innerHTML = message
    }
  }

  _closeModal () {
    closeModal(this.element)

    if (this.hasMessageTarget) {
      this.messageTarget.innerHTML = ''
    }
  }
}
