import { Controller } from '@hotwired/stimulus';
import {
  csrfToken
} from '../application/javascripts/helpers/api_requests_helper';

export default class extends Controller {
  static values = {
    notificationId: String
  }

  connect () {
    this.element[this.identifier] = this

    if (this._visible()) {
      this._sendRead()
    }

    this._refreshList()
  }

  disconnect () {
    this._refreshList()
  }

  async dismiss () {
    await this._sendDismiss()
    this._closeModal()
  }

  _refreshList () {
    let el = document.querySelector('*[data-controller="notification-list"]')['notification-list']
    if(el !== undefined) el.refresh()
  }

  async _sendRead () {
    try {
      const result = await fetch('/notifications/' + this.notificationIdValue + "/read", {
        headers: {
          'X-CSRF-Token': csrfToken()
        },
        method: 'POST',
        credentials: 'include'
      })

      const data = await result.json()

      return data
    } catch (e) {
      return undefined
    }
  }

  async _sendDismiss () {
    try {
      const result = await fetch('/notifications/' + this.notificationIdValue + '/dismiss', {
        headers: {
          'X-CSRF-Token': csrfToken()
        },
        method: 'POST',
        credentials: 'include',
      })

      const data = await result.json()

      return data
    } catch (e) {
      return undefined
    }
  }

  _visible () {
    return window.getComputedStyle(this.element.parentElement).display !== 'none'
  }

  _closeModal () {
    const modal = this.element.closest('*[data-controller="modal"]')
    modal['modal'].closeModal()
  }
}
