import { tsMethodSignature } from '@babel/types';
import { Controller } from '@hotwired/stimulus';
import Chart from 'chart.js/auto';

import {
  generateTransactionPeriod,
  getDataPeriod,
  baseChartConfig
} from '../../application/javascripts/helpers/live_dashboard_helper';

export default class extends Controller {
  static targets = ['transactionStatusCountGraph', 'transactionStatusCount', 'filters'];

  connect() {

    this.dataPeriod = getDataPeriod();

    this.transactionsObserver = new MutationObserver(
      this._updateChart.bind(this),
    );
    this.transactionsObserver.observe(this.transactionStatusCountTarget.parentNode, {
      childList: true,
    });

    this.transactionsObserver.observe(this.filtersTarget, {
      childList: true,
      attributes: true
    });

    if (
      this.transactionStatusCountTarget.children[0].value &&
      this.dataPeriod
    ) {
      this.generateChart(this._chartConfig());
    }
  }

  generateChart(config) {
    this.chart = new Chart(this.transactionStatusCountGraphTarget, config)
  }

  _chartLabels() {
    return JSON.parse(this.transactionStatusCountTarget.children[0].value).labels;
  }

  _chartData() {
    return JSON.parse(this.transactionStatusCountTarget.children[0].value).data;
  }

  _getFilters () {
    let filters = null;

    if (this.filtersTarget.value) {
      filters = JSON.parse(this.filtersTarget.value)
    }

    return filters;
  }

  _getShopIds() {
    let shopIds = [];
    let filters = this._getFilters();

    if (filters) {
      shopIds = filters.shops.map((item) => parseInt(item))
    }

    return shopIds;
  }

  _datasetConfig(type) {
    switch(type) {
      case 'success':
        return {
          label: 'Successful',
          color: 'rgba(90, 190, 77, 0.7)'
        }
      case 'failed':
        return {
          label: 'Declined',
          color: 'rgba(218, 36, 78, 0.7)'
        }
      case 'pending':
        return {
          label: 'Pending',
          color: 'rgba(228, 113, 50, 0.7)'
        }
    }
  }

  _generateDatasets() {
    this.STATUS_DATA = {
      success: generateTransactionPeriod(this.dataPeriod),
      pending: generateTransactionPeriod(this.dataPeriod),
      failed: generateTransactionPeriod(this.dataPeriod)
    }

    const shopIds = this._getShopIds();

    this._chartData().forEach((datum) => {
      if(shopIds.includes(datum.shop)) {
        datum.dataset.forEach((value, index) => {
          this.STATUS_DATA[datum.type][index] += value;
        })
      }
    });

    return Object.entries(this.STATUS_DATA).map(([status, data]) => {
      const config = this._datasetConfig(status);

      return {
        label: config.label,
        data: data,
        borderColor: config.color,
        backgroundColor: config.color,
        color: 'rgb(137, 139, 150)',
        tension: 0.1
      }
    })
  }

  _updateChart() {
    if (
      this.hasTransactionStatusCountTarget &&
      this.transactionStatusCountTarget.children[0].value &&
      this.dataPeriod
    ) {
      this._generateDatasets().forEach((dataset) => {
        this._updateDataset(dataset.label, dataset.data)
      })

      this._updateLabels();

      this.chart?.update();
    }
  }

  _updateDataset(key, data) {
    const dataset = this.chart.data.datasets.find((dataset) => {
      return dataset.label === key;
    });

    dataset.data = data;
  }

  _updateLabels() {
    if (this.chart) {
      this.chart.data.labels = this._chartLabels();
    }
  }

  _chartConfig() {
    let config = baseChartConfig('Transaction Status Counts', 'line')

    config.data = {
      labels: this._chartLabels(),
      datasets: this._generateDatasets(),
    }

    return config;
  }
}
