import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'parent',
    'nav',
    'pages',
    'subNav',
    'navBar',
    'cover'
  ];
  static activeClass = 'hexo-navigation__item--active'

  connect () {
    this.currentSubNav = ''
    this._buildNavBar()
    this._appendCover()
  }

  toggleSubNav (e) {
    e.preventDefault();

    const target = e.target.closest('li')
    const key = target.dataset.key

    if (this.currentSubNav == key ) {
      this.closeNav()
    } else if (
        this.currentSubNav &&
        this.currentSubNav != key
    ) {
      this._switchSubNav(target, key)
    } else {
      this._openSubNav(target, key)
    }
  }

  tabletToggle (e) {
    e.preventDefault()
    this.pagesTarget.classList.toggle('wide')
    this.navTarget.classList.toggle('top')

    if (this.pagesTarget.classList.contains('wide')) {
      this._showCover()
    } else {
      this._hideCover()
    }
  }

  closeNav (e) {
    this._hideCover()
    this._hideSubNav()
    this._lowerNavBar()
    this.pagesTarget.classList.remove('wide')
    this.navTarget.classList.remove('top')
    this.currentSubNav = ''
    this._unsetActiveTab();
  }

  _setActiveTab (target) {
    this._unsetActiveTab()
    target.querySelector('a').classList.add(this.constructor.activeClass)
  }

  _unsetActiveTab () {
    this.navBarTarget.querySelectorAll('.' + this.constructor.activeClass).forEach((i) => {
      i.classList.remove(this.constructor.activeClass)
    })
  }

  _switchSubNav (target, key) {
    this._hideSubNav();
    setTimeout(() => {
      this._buildSubNav(target, key)
      this._showSubNav()
    }, 250)
    this.currentSubNav = key
    this._setActiveTab(target)
  }

  _openSubNav (target, key) {
    this._buildSubNav(target, key)
    this._showCover()
    this._showSubNav()
    this._raiseNavBar()
    this.currentSubNav = key
    this._setActiveTab(target)
  }

  _showSubNav () {
    this.subNavTarget.classList.add('show')
  }

  _hideSubNav () {
    this.subNavTarget.classList.remove('show')
  }

  _raiseNavBar () {
    this.navBarTarget.classList.add('top')
  }

  _lowerNavBar () {
    this.navBarTarget.classList.remove('top')
  }

  _showCover () {
    this.coverTarget.classList.add('hexo-cover__show')
  }

  _hideCover () {
    this.coverTarget.classList.remove('hexo-cover__show')
  }

  _buildSubNav(target, key) {
    let link = target.cloneNode(true);
    link.dataset.action = ''
    link.querySelector('a').classList.remove(this.constructor.activeClass)

    this.subNavTarget.innerHTML = ''
    this.subNavTarget.append(link)

    const selector = 'li[data-parent=' + key + ']'
    const items = this.pagesTarget.querySelectorAll(selector)
    items.forEach((e) => {
      this.subNavTarget.append(e.cloneNode(true))
    })
  }

  _buildNavBar () {
    this.pagesTarget.querySelectorAll('li').forEach((i) => {
      if (!i.dataset.parent) {
        const action = 'click->merchants--navigation#toggleSubNav'
        let clone = i.cloneNode(true);
        clone.dataset.action = action

        this.navBarTarget.append(clone)
      }
    })
  }

  _appendCover () {
    let cover = document.createElement('div');
    cover.classList.add('hexo-cover');
    cover.setAttribute(
      'data-action',
      'click->merchants--navigation#closeNav'
    )
    cover.setAttribute(
      'data-merchants--navigation-target',
      'cover'
    )

    this.parentTarget.append(cover)
  }
}
