import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ "pricePointTable" ]

  connect() {
    this.setupEventListeners();
  }

  setupEventListeners() {
    let select = document.getElementById("price_point_currency");
    select.addEventListener("change", () => {
      this.fetchPricePoints();
    });
  }

  fetchPricePoints() {
    let selectedCurrency = document.getElementById("price_point_currency").value;
    let gatewayId = this.gatewayId;
    let url = `/gateways/${gatewayId}/price_points?currency=${selectedCurrency}`;
  
    fetch(url)
      .then(response => response.json())
      .then(pricePoints => {
        this.renderPricePoints(pricePoints);
      });
  }

  renderPricePoints(pricePoints) {
    let pricePointsTable = this.pricePointTableTarget;
    pricePointsTable.innerHTML = "";
    pricePoints.forEach(pricePoint => {
      pricePointsTable.innerHTML += `
        Amount: ${pricePoint.amount}
        <a href="/account_manager/shops/${this.shopId}/gateways/${this.gatewayId}/price_points/${pricePoint.id}" data-method="delete" data-confirm="Are you sure?">Delete</a>
        <br>
        <br>
      `;
    });
  }

  get gatewayId() {
    return this.data.get("gatewayId");
  }

  get shopId() {
    return this.data.get("shopId");
  }
}
