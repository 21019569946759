import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    setting: String,
    setAs: String
  }

  update (e) {
    e.preventDefault()
    this._sendRequest()
  }

  _sendRequest () {
    fetch('/merchant/settings/' + this.settingValue + '/set', {
      body: JSON.stringify({ value: this.setAsValue }),
      headers: {
        'accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      },
      method: 'POST',
      credentials: 'include'
    }).then((r) => {
      window.location.reload();
    })
  }
}
