import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['parent', 'timer']
  static values = {
    timed: Boolean,
    closeable: Boolean
  }

  connect () {
    if (this.timedValue) {
      // Append Timer
      this._appendTimer()
      // Start Timer
      this._startTimer()
    }

    if (this.closeableValue) {
      // Append close button
      this._appendCloseButton()
    }
  }

  close (e) {
    e.preventDefault()
    this._closeAlert();
  }

  _appendTimer() {
    let timer = document.createElement('div')
    timer.classList.add('hexo-alert__timer')
    timer.dataset.alertTarget = 'timer'

    this.parentTarget.append(timer)
  }

  _startTimer () {
    setTimeout(() => this.timerTarget.classList.add('start'), 1);
    setTimeout(() => {
      this._closeAlert();
    }, 10001);
  }

  _appendCloseButton () {
    let closeIcon = document.createElement('span')
    closeIcon.classList.add('hexo-icon')
    closeIcon.classList.add('hexo-icon--close')
    closeIcon.classList.add('hexo-icon--white')

    let closeButton = document.createElement('button')
    closeButton.classList.add('hexo-alert__close')
    closeButton.dataset.action = 'click->alert#close'
    closeButton.append(closeIcon)

    this.parentTarget.append(closeButton)
  }

  _closeAlert () {
    this.parentTarget.classList.add('hide');
    setTimeout(() => this.parentTarget.remove(), 500);
  }
}
