import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['parent', 'bar', 'userMenu', 'userToggle', 'cover'];

  connect () {
    let coverEl = document.createElement('div')
    coverEl.classList.add('hexo-cover');
    coverEl.dataset.action = 'click->header#closeHeader'
    coverEl.dataset.headerTarget = 'cover'

    this.parentTarget.append(coverEl)
    this.userMenuActive = false
  }

  openPanel (e) {
    e.preventDefault();

    const button = e.target.closest('*[data-action]')
    const panel = button.dataset.headerTarget
    const target = this._findPanel(panel)

    if (this.currentHeaderPanel && this.currentHeaderPanel == panel) {
      this.closeHeader()
    } else if (this.currentHeaderPanel && this.currentHeaderPanel != panel) {
      const currentTarget = this._findPanel(this.currentHeaderPanel)

      currentTarget.classList.remove('show')
      this._inactivateButtons()

      setTimeout(() => {
        target.classList.add('show')
        button.classList.add('active')
      }, 250)
      this.currentHeaderPanel = panel
    } else {
      this.coverTarget.classList.add('hexo-cover__show')
      this.barTarget.classList.add('top')
      target.classList.add('show')
      button.classList.add('active')
      this.currentHeaderPanel = panel
    }
  }

  closeHeader () {
    this.coverTarget.classList.remove('hexo-cover__show')
    this.barTarget.classList.remove('top')
    const currentTarget = this.element.querySelector('*[data-target="' + this.currentHeaderPanel + '"]')
    currentTarget.classList.remove('show')
    this.currentHeaderPanel = null
    this._inactivateButtons()
  }

  _inactivateButtons () {
    this.element.querySelectorAll('*[data-header-target]').forEach((i) => i.classList.remove('active'))
  }

  _findPanel (key) {
    return this.element.querySelector('*[data-target="' + key + '"]')
  }
}
