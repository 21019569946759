import { Controller } from '@hotwired/stimulus';
import {
  csrfToken
} from '../../application/javascripts/helpers/api_requests_helper';

export default class extends Controller {
  static targets = ['error']
  static values = {
    url: String
  }
  static renderSetsValue = 'hex-protect--sets-render'
  static modalValue = 'modal'

  connect () {
    this.element[this.identifier] = this
  }

  async delete () {
    const data = await this._sendDelete()

    if (data) {
      this._handleDeleteResponse(data)
    }
  }

  async _sendDelete () {
    let response = undefined

    if (this.hasUrlValue) {
      try {
        const result = await fetch(this.urlValue, {
          headers: {
            'X-CSRF-Token': csrfToken(),
          },
          method: 'DELETE',
          credentials: 'include'
        })

        response = await result.json()
      } catch (e) {
        response = { status: 'Failed', message: e }
      }

    } else {
      this._showError({ message: 'No URL supplied' })
    }

    return response;
  }

  _handleDeleteResponse (data) {
    switch (data['status']) {
      case 'deleted':
        this._closeModal()
        this._refreshSets()
        break
      default:
        this._showError(data)
    }
  }

  _refreshSets () {
    const render = this.element.closest(this._targetString(this.constructor.renderSetsValue))
    if (render['hex-protect--sets-render']) {
      render['hex-protect--sets-render'].refresh()
    }
  }

  _closeModal () {
    const modal = this.element.closest(this._targetString(this.constructor.modalValue))
    if (modal['modal']) {
      modal['modal'].closeModal()
    }
  }

  _targetString (target) {
    return '*[data-controller="' + target + '"]'
  }

  _showError (data) {
    const message = 'Error: ' + data['message']

    if (this.hasErrorTarget) {
      this.errorTarget.innerHTML = message
    } else {
      const error = document.createElement('div')
      error.setAttribute('data-' + this.identifier + '-target', 'error')
      error.classList.add('hex-protect__error')
      error.innerHTML = message

      this.element.append(error)
    }
  }
}
