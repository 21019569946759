import { Controller } from '@hotwired/stimulus';
import {
  csrfToken
} from '../../application/javascripts/helpers/api_requests_helper';
import {
  capitalize
} from '../../application/javascripts/helpers/text_helper';
import {
  buildIconModal,
  largeModalIcon,
  modalText,
  closeModalButton,
  buildModalButton,
  buildModalButtonGroup
} from '../../application/javascripts/helpers/modal_builder_helper';

export default class extends Controller {
  static values = {
    errorMessage: String,
    emptyMessage: String
  }

  static defaultMessage = 'Please select an option'
  static emptyMessage = 'No rules created yet'
  static errorMessage = 'An error occurred. Please try again later.'
  static ruleKeys = ['alias', 'condition', 'ops']

  connect () {
    this.element[this.identifier] = this

    this._setDefaultView()
    this.uuid = undefined
  }

  async render (uuid) {
    this.uuid = uuid
    await this._buildRulesContent()
  }

  async refresh () {
    await this._buildRulesContent()
  }

  async _buildRulesContent () {
    const data = await this._fetchRulesForUUID()

    this.element.innerHTML = ''

    if (data && data.length > 0) {
      data.forEach((r) => this.element.append(this._buildRule(r)))
    } else if (data) {
      this.element.append(this._buildEmptyMessage())
    } else {
      this.element.append(this._buildErrorMessage())
    }
  }

  _buildRule (rule) {
    const el = document.createElement('div')
    el.classList.add('hexo-rule')
    this.constructor.ruleKeys.forEach((k) => { el.append(this._buildSection(k, rule)) })
    return el
  }

  _buildSection (key, rule) {
    const el = document.createElement('div')

    el.classList.add('hexo-rule__' + key)
    el.innerHTML = this._contentForSection(key, rule)

    return el
  }

  _contentForSection (key, rule) {
    let content = ''

    switch (key) {
      case 'ops':
        content = buildIconModal('close', this._deleteConfirmation(rule), 'danger')
        break;
      case 'condition':
        content = "<p>" + rule['condition'] + "</p><p><b>Action:</b> " + capitalize(rule['action']) + "</p>"
        break;
      default:
        content = rule[key]
    }

    return content
  }

  _deleteConfirmation (rule) {
    const wrapper = document.createElement('div')

    wrapper.append(largeModalIcon('danger'))
    wrapper.append(modalText('Are you sure you want to delete this rule?'))
    wrapper.append(this._buildActions(rule))

    return wrapper.innerHTML
  }

  _buildActions (rule) {
    const actions = buildModalButtonGroup()
    actions.dataset.controller = 'hex-protect--delete-rule'
    actions.setAttribute('data-hex-protect--delete-rule-url-value', '/merchant/rules/' + rule['uuid'] + '?uid=' + this.uuid)

    const deleteButton = buildModalButton('Delete Rule', 'danger')
    deleteButton.dataset.action = 'click->hex-protect--delete-rule#delete'


    actions.append(closeModalButton('Cancel'))
    actions.append(deleteButton)

    return actions
  }

  _buildEmptyMessage () {
    const el = document.createElement('p')
    el.classList.add('text--center')
    el.innerHTML = this._emptyMessage()

    return el
  }

  _emptyMessage () {
    if (this.hasEmptyMessageValue) {
      return this.emptyMessageValue
    } else {
      return this.constructor.emptyMessage
    }
  }

  _buildErrorMessage () {
    const el = document.createElement('p')
    el.classList.add('text--center')
    el.innerHTML = this._errorMessage()

    return el
  }

  _errorMessage () {
    if (this.hasErrorMessageValue) {
      return this.errorMessageValue
    } else {
      return this.constructor.errorMessage
    }
  }

  async _fetchRulesForUUID () {
    try {
      const result = await fetch('/merchant/rules?uid=' + this.uuid, {
        headers: {
          'X-CSRF-Token': csrfToken(),
        },
        method: 'GET',
        credentials: 'include'
      })

      const data = await result.json()

      return data['rules']
    } catch (e) {
      return undefined
    }
  }

  _setDefaultView () {
    this.element.innerHTML = ''
    this.element.append(this._buildMessage())
  }

  _buildMessage () {
    const message = document.createElement('p')
    message.classList.add('text--center')
    message.innerHTML = this.constructor.defaultMessage
    return message
  }
}
