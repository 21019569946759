import { Controller } from '@hotwired/stimulus';
import Chart from 'chart.js/auto';

import {
  generateTransactionPeriod,
  getDataPeriod,
  baseChartConfig,
  currencyToolTip,
  currencyTickScale
} from '../../application/javascripts/helpers/live_dashboard_helper';

export default class extends Controller {
  static targets = ['averageTransactionGraph', 'averageVolumeAndCount', 'filters'];
  static averageLabel = 'Average Transaction Value';
  static countLabel = 'Count';

  connect() {
    this.dataPeriod = getDataPeriod();

    this.transactionsObserver = new MutationObserver(
      this._updateChart.bind(this),
    );
    this.transactionsObserver.observe(
      this.averageVolumeAndCountTarget.parentNode,
      {
        childList: true,
      },
    );

    this.transactionsObserver.observe(this.filtersTarget, {
      childList: true,
      attributes: true
    });

    if (this.averageVolumeAndCountTarget.children[0].value) {
      this._generateChart();
    }
  }

  disconnect() {
    this.transactionsObserver.disconnect();
  }

  _chartLabels() {
    return JSON.parse(this.averageVolumeAndCountTarget.children[0].value).labels
  }

  _getFilters () {
    let filters = null;

    if (this.filtersTarget.value) {
      filters = JSON.parse(this.filtersTarget.value)
    }

    return filters;
  }

  _getShopIds() {
    let shopIds = [];
    let filters = this._getFilters();

    if (filters) {
      shopIds = filters.shops.map((item) => parseInt(item))
    }

    return shopIds;
  }

  _combineData() {
    const data = JSON.parse(this.averageVolumeAndCountTarget.children[0].value).data;

    let formattedData = {
      amount: generateTransactionPeriod(this.dataPeriod),
      count: generateTransactionPeriod(this.dataPeriod),
      average: generateTransactionPeriod(this.dataPeriod),
    };

    const shopIds = this._getShopIds();

    data.forEach((datum) => {
      if (shopIds.includes(datum.shop)) {
        formattedData[datum.type]?.forEach((_entry, index) => {
          formattedData[datum.type][index] += parseFloat(datum.dataset[index]);
        });
      }
    });

    return formattedData;
  }

  _calculateAverageValues() {
    let formattedData = this._combineData();

    formattedData['average']?.forEach((_datum, index) => {
      const amount = formattedData['amount'][index];
      const count = formattedData['count'][index];
      let value = parseFloat('0.00').toFixed(2);

      if (amount > 0 && count > 0) {
        value = (amount / count).toFixed(2);
      }

      formattedData['average'][index] = value;
    });

    this.AVERAGE_DATASET = formattedData['average'];
    this.COUNT_DATASET = formattedData['count'];
  }

  _generateChart() {
    this._calculateAverageValues();

    let config = baseChartConfig('Transaction count vs avg. transaction value', 'line')

    config.data = {
      labels: this._chartLabels(),
      datasets: [
        {
          label: this.constructor.countLabel,
          data: this.COUNT_DATASET,
          yAxisID: 'y1',
          borderColor: 'rgb(50, 134, 184)',
          backgroundColor: 'rgb(50, 134, 184)',
          color: 'rgb(137, 139, 150)',
          tension: 0.1
        },
        {
          label: this.constructor.averageLabel,
          data: this.AVERAGE_DATASET,
          yAxisID: 'y',
          borderColor: 'rgb(75, 173, 181)',
          backgroundColor: 'rgb(188, 234, 237)',
          fill: true,
          color: 'rgb(137, 139, 150)',
          tension: 0.1
        },
      ],
    }

    config.options.scales.y.ticks = currencyTickScale('EUR')
    config.options.scales.y1 = {
      type: 'linear',
      display: true,
      position: 'right',
      min: 0,
      suggestedMax: 100,
      grid: {
        display: false,
      },
    }

    config.options.plugins.tooltip = {
      callbacks: {
        label: function (context) {
          let label = context.dataset.label || '';
          if (label) {
            label += ': ';
          }
          if (
            context.parsed.y !== null &&
            context.dataset.yAxisID === 'y'
          ) {
            label += new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'EUR',
            }).format(context.parsed.y);
          } else {
            label += context.parsed.y;
          }
          return label;
        },
      },
    }

    this.chart = new Chart(this.averageTransactionGraphTarget, config);
  }

  _updateChart() {
    if (
      this.hasAverageVolumeAndCountTarget &&
      this.averageVolumeAndCountTarget.children[0].value &&
      this.dataPeriod
    ) {
      this._calculateAverageValues();

      this._updateDataset(this.constructor.countLabel, this.COUNT_DATASET);
      this._updateDataset(this.constructor.averageLabel, this.AVERAGE_DATASET);
      this._updateLabels();

      this.chart?.update();
    }
  }

  _updateDataset(key, data) {
    const dataset = this.chart.data.datasets.find((dataset) => {
      return dataset.label === key;
    });

    dataset.data = data;
  }

  _updateLabels() {
    if (this.chart) {
      this.chart.data.labels = this._chartLabels();
    }
  }
}
