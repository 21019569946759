import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['shops', 'rules', 'sets', 'select']
  static values = {
    merchantKey: String
  }

  static hiddenFieldClass = 'hidden'

  connect () {
    this.element[this.identifier] = this

    this.merchantKey = undefined
    this.shopKey = undefined
    this.level = undefined
  }

  levelChange (value) {
    switch (value) {
      case 'Merchant':
        this.level = value
        this._hideShops()

        if (this.hasMerchantKeyValue) {
          this.merchantKey = this.merchantKeyValue
          this._renderData()
        }

        break;
      case 'Shop':
        this.level = value
        this._showShops()
    }
  }

  shopChange (value) {
    this.shopKey = value

    if (this.level === 'Shop') {
      this._renderData(value)
    }
  }

  currentDetails () {
    return {
      level: this.level,
      uuid: this._uuid()
    }
  }

  _uuid () {
    let uuid = undefined

    switch (this.level) {
      case 'Merchant':
        uuid = this.merchantKey
        break
      case 'Shop':
        uuid = this.shopKey
        break
    }

    return uuid
  }

  _renderData () {
    this._requestRulesRender(this._uuid())
    this._requestSetsRender(this._uuid())
  }

  _showShops () {
    if (this.hasShopsTarget) {
      this.shopsTarget.classList.remove(this.constructor.hiddenFieldClass)
    }
  }

  _hideShops () {
    if (this.hasShopsTarget) {
      this.shopsTarget.classList.add(this.constructor.hiddenFieldClass)
    }
  }

  _requestRulesRender (uuid) {
    if (this.hasRulesTarget) {
      this.rulesTarget['hex-protect--rules-render'].render(uuid)
    }
  }

  _requestSetsRender (uuid) {
    if (this.hasSetsTarget) {
      this.setsTarget['hex-protect--sets-render'].render(uuid)
    }
  }
}
