import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['form', 'results', 'loading']
  static values = {
    url: String
  }

  connect () {
    this.loadingTarget.classList.add('hidden')
  }

  submit (e) {
    const data = new FormData(e.target)
    data.delete('_method')
    
    this._showLoading()
    this._sendForm(data)

    e.preventDefault()
  }

  _showLoading () {
    if (this.hasLoadingTarget && this.hasResultsTarget) {
      this.resultsTarget.classList.add('hidden')
      this.loadingTarget.classList.remove('hidden')
    }
  }

  _sendForm (data) {
    fetch(this.urlValue, {
      body: data,
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      },
      method: 'POST',
      credentials: 'include'
    }).then((r) => r.text()).then((text) => {
      const json = JSON.parse(text)
      this.resultsTarget.innerHTML = ''
      this.resultsTarget.append(this._buildResults(json))
      this.loadingTarget.classList.add('hidden')
      this.resultsTarget.classList.remove('hidden')
    })
  }

  _buildResults (response) {
    const tableHeader = document.createElement('thead')
    response['headings'].forEach(h => {
      const heading = document.createElement('th')
      heading.classList.add('text--left')
      heading.innerHTML = h.header

      tableHeader.append(heading)
    });

    const tableBody = document.createElement('tbody')
    response.rows.forEach((r) => {
      const trow = document.createElement('tr')

      response.headings.forEach((h) => {
        const col = document.createElement('td')
        col.innerHTML = (r[h.key]) ? r[h.key] : ''

        trow.append(col)
      })

      tableBody.append(trow)
    })

    const table = document.createElement('table')
    table.classList.add('hexo-table')
    table.classList.add('hexo-table__details')
    table.append(tableHeader)
    table.append(tableBody)

    return table
  }
}
