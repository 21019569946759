export const buildIconModal = (icon, content, color) => {
  const modal = document.createElement('div')
  modal.dataset.controller = 'modal'
  modal.dataset.modalTarget = 'parent'

  modal.append(buildButton(icon, color))
  modal.append(buildData(content))
  
  return modal.outerHTML
}

export const largeModalIcon = (klass) => {
  const icon = document.createElement('div')
  icon.classList.add('text--center')

  const span = document.createElement('span')
  span.classList.add('hexo-alert-icon')
  span.classList.add('hexo-alert-icon--' + klass)

  icon.append(span)

  return icon
}

export const modalText = (text) => {
  const el = document.createElement('p')
  el.classList.add('text')
  el.classList.add('text--center')
  el.classList.add('hexo-margin__top')
  el.classList.add('hexo-margin__bottom')
  el.innerHTML = text

  return el
}

export const closeModalButton = (text) => {
  const el = buildModalButton(text, 'cancel')
  el.dataset.action = 'click->modal#closeModal'

  return el
}

export const buildModalButton = (text, color) => {
  const el = document.createElement('button')
  el.type = 'button'
  el.classList.add('hexo-btn-default')
  el.classList.add('hexo-btn-default__' + color)
  el.innerHTML = text

  return el
}

export const buildModalButtonGroup = () => {
  const el = document.createElement('div')
  el.classList.add('flex-center')
  el.classList.add('flex-small-gap')

  return el
}

const buildData = (content) => {
  const data = document.createElement('div')
  data.dataset.modalTarget = 'content'
  data.style = 'display: none'
  data.innerHTML = content

  return data
}

const buildButton = (icon, color) => {
  const button = document.createElement('button')
  button.dataset.action = 'click->modal#openModal'
  button.classList.add('hexo-btn__icon')

  button.append(buildIcon(icon))

  if (color) {
    button.classList.add('hexo-btn__' + color)
  } else {
    button.classList.add('hexo-btn__primary')
  }

  return button
}

const buildIcon = (klass) => {
  const icon = document.createElement('span')
  icon.classList.add('hexo-icon')
  icon.classList.add('hexo-icon--small')
  icon.classList.add('hexo-icon--' + klass)

  return icon
}
