import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["mainCountdown", "popupCountdown", "endSessionWrapper", "mainCountdownWrapper"]
  static values = {
    countFrom:      Number,
    counter:        Number,
    popupCountFrom: Number,
    popupCounter:   Number
  }

  textToFavicon(str='1') {
    let canvas = document.createElement("canvas");
    canvas.setAttribute('width', 32)
    canvas.setAttribute('height', 32)
    const ctx = canvas.getContext("2d");
    ctx.font = "30px serif";
    ctx.fillText(str, 0, 28);
    let faviconLink = document.querySelector('link[rel*="icon"]');
    faviconLink.href = canvas.toDataURL('image/png');
  }

  format(number, leadingSymbol='0') { return number < 10 ? leadingSymbol + number : number }

  secondsToMinutes(seconds, leading = true) {
    "900 -> 15:00"
    "899 -> 14:59"
    "898 -> 14:58"
    "500 -> 08:20"
    " 20 -> 00:20"
    const secondsInMinute = 60
    let minutes = seconds / secondsInMinute | 0
    let restSeconds = seconds - minutes * secondsInMinute

    return `${this.format(minutes)}:${this.format(restSeconds)}`
  }


  logout(that) {
    document.querySelector('.logout').click();
  }

  timerToString() {
    return `
      let tID
      let counter

      onmessage = function(e) {
        console.log('Worker: Message received from main script');
        function timer() {
          postMessage({ counter: counter })
          if (counter == 0) {
            clearInterval(tID)
            return
          }
          counter--
        }

        if ( e.data.action === 'start' ) {
          // console.log('START')
          counter = e.data.countFrom
          timer()
          tID = setInterval(timer, 1000)
        }
        if ( e.data.action === 'stop' ) {
          // console.log('STOP')
          clearInterval(tID)
        }
      }
    `
  }

  showPopup() {
    let el = this.endSessionWrapperTarget
    el.style.display = 'block'
  }

  hidePopup() {
    let el = this.endSessionWrapperTarget
    el.style.display = 'none'
  }

  changerOnMainCountdown(that, target, countFrom, counter) {
    target.innerHTML = that.secondsToMinutes(counter)
  }
  changerOnPopupCountdown(that, target, countFrom, counter) {
    target.innerHTML = counter
    that.textToFavicon(counter)
  }

  blob = new Blob([this.timerToString()], { type: "text/javascript" })
  w = new Worker(URL.createObjectURL(this.blob));

  // function to start the timer
  startTimerWorker(that, target, nextFunc, countFrom, changerFunc)  {
    // First check whether Web Workers are supported
    if (typeof (Worker) === "undefined") {
      // Web workers are not supported by your browser
      console.log("Sorry, your browser does not support Web Workers ...")

      return
    }

    this.w.postMessage({ action: 'stop' })
    this.w.postMessage({ action: 'start', countFrom: countFrom })


    this.w.onmessage = function (event) {
      changerFunc(that, target, countFrom, event.data.counter)
      if (event.data.counter == 0) {
        nextFunc(that)
      }
    }
  }

  startPopupShow(that) {
    that.showPopup()
    that.startTimerWorker(that, that.popupCountdownTarget, that.logout, that.popupCountFromValue, that.changerOnPopupCountdown)
  }

  iconChange(mode='normal') {
    let favicon = document.querySelector('link[rel*="icon"]');
    let iconPath = mode == 'normal' ? "/favicon.ico" : "/favicon-red.ico"
    favicon.setAttribute("href", iconPath);
  }

  startTimer(event) {
    // Check if event from this controller timer#logout()
    if (event && ['click'].includes(event.type) && event.pointerType === '') { return }

    this.hidePopup()
    this.iconChange()
    this.startTimerWorker(this, this.mainCountdownTarget, this.startPopupShow, this.countFromValue, this.changerOnMainCountdown)
  }

  connect() {
    let ev = new Event('connect')
    this.startTimer(ev)
  }

  disconnect() {
    this.stopTimer()
  }

  touch() {
    this.startTimer()
  }
}
