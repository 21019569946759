import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['parent', 'select', 'field', 'modal', 'modalCover', 'options', 'selected', 'input', 'searchField'];
  static values = {
    url: String,
    param: String,
    changeTarget: String,
    changeMethod: String
  }
  static searchLength = 10

  connect () {
    this.element[this.identifier] = this

    if (this.hasSelectTarget) {
      this.options = this._allOptions()
      this.selectedOption = this._selectedOption()
      this.fieldTarget.append(this._createSelected())
      this._appendModal();

      let hiddenInput = document.createElement('input')
      hiddenInput.type = 'hidden'
      hiddenInput.name = this.selectTarget.name
      hiddenInput.setAttribute('data-fields--select-target', 'input')

      this.fieldTarget.append(hiddenInput)
      this._setInput()
      this.selectTarget.remove()

      if (this.options.length > this.constructor.searchLength) {
        this._enableSearch()
      }
      if (this.hasUrlValue) {
        this._loadOptions()
        this.selectedTarget.innerHTML = 'Loading...'
      }
    }

  }

  open (e) {
    e.preventDefault()
    this._openModal()
    if (this.options.length > this.constructor.searchLength) {
      this.searchFieldTarget.focus()
    }
  }

  close (e) {
    e.preventDefault()
    this._closeModal()
    this.modalTarget.classList.remove('open')
    this.modalCoverTarget.classList.remove('hexo-cover__show')
    this._setInput()
  }

  reset () {
    this.optionsTarget.querySelectorAll('.selected').forEach((el) => {
      el.classList.remove('selected')
    })

    const o = this.optionsTarget.querySelector('button:first-child')
    o.classList.add('selected')

    if (o) {
      this.selectedOption = o.dataset.value
      this.selectedTarget.innerHTML = this._selectedText()
    }
  }

  toggleValue (e) {
    e.preventDefault()

    this.optionsTarget.querySelectorAll('.selected').forEach( e => e.classList.remove('selected') )

    const t = e.target
    t.classList.toggle('selected')

    this.close(e)
  }

  search (e) {
    let fieldText = this.searchFieldTarget.value.toLowerCase().split(' ')

    this.optionsTarget.querySelectorAll('button').forEach(o => {
      let optionText = o.innerHTML.toLowerCase()
      if (fieldText.length > 0) {
        let finds = 0
        fieldText.forEach(i => {
          if ( optionText.includes(i) ) {
            finds++
          }
        })

        if (finds > 0) {
          o.classList.remove('hidden')
        } else {
          o.classList.add('hidden')
        }
      } else {
        o.classList.remove('hidden')
      }
    })

    if (this.optionsTarget.querySelectorAll('button:not(.hidden)').length == 0) {
      this.optionsTarget.classList.add('empty')
    } else {
      this.optionsTarget.classList.remove('empty')
    }
  }

  _enableSearch () {
    let searchField = document.createElement('input')
    searchField.type = 'text'
    searchField.classList.add('hexo-field__input')
    searchField.dataset.action = 'input->fields--select#search'
    searchField.setAttribute('data-fields--select-target', 'searchField')
    searchField.placeholder = 'Filter options...'

    let searchHolder = document.createElement('div')
    searchHolder.classList.add('hexo-field')

    let searchEl = document.createElement('div')
    searchEl.classList.add('hexo-select-list__filter')

    searchHolder.append(searchField)
    searchEl.append(searchHolder)
    this.optionsTarget.prepend(searchEl)
    this.optionsTarget.classList.add('hexo-select-list--searchable')
  }

  _setInput () {
    this.inputTarget.value = this.selectedOption
    this._handleChange()
  }

  _handleChange () {
    if (this.hasChangeTargetValue && this.hasChangeMethodValue) {
      const el = this._findChangeTarget()
      if (el[this.changeTargetValue]) {
        const target = el[this.changeTargetValue]
        eval(this._buildChangeMethod())
      }
    }
  }

  _findChangeTarget () {
    return this.element.closest(
      '*[data-controller=' + this.changeTargetValue + ']'
    )
  }

  _buildChangeMethod () {
    return 'target.' +
      this.changeMethodValue +
      "('" +
      this.selectedOption +
      "')"
  }

  _allOptions () {
    const options = this.selectTarget.querySelectorAll('option')
    let formattedOptions = []

    options.forEach(o => formattedOptions.push({ value: o.value, text: o.text }))

    return formattedOptions
  }

  _loadOptions () {
    fetch(this.urlValue, {
      headers: {
        'accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      },
      method: 'GET',
      credentials: 'include'
    }).then((r) => r.text()).then((text) => {
      const options = JSON.parse(text)
      options.forEach((option) => {
        this.options.push({ value: option[0], text: option[1] })

        let el = document.createElement('button')
        el.type = 'button'
        el.classList.add('hexo-select-list__option')
        el.innerHTML = option[1]
        el.dataset.value = option[0]
        el.dataset.action = 'click->fields--select#toggleValue'

        if ( this.selectedOption == option[0] ) {
          el.classList.add('selected')
        }

        this.optionsTarget.append(el)
      })

      this.selectedOption = options[0][0]
      this.selectedTarget.innerHTML = this._selectedText()
      this._setInput()
    })
  }

  _openModal () {
    this.modalTarget.classList.add('open')
    this.modalCoverTarget.classList.add('hexo-cover__show')
  }

  _closeModal () {
    this.modalTarget.classList.remove('open')
    this.modalCoverTarget.classList.remove('hexo-cover__show')

    const o = this.optionsTarget.querySelector('.selected')

    if (o) {
      this.selectedOption = o.dataset.value
      this.selectedTarget.innerHTML = this._selectedText()
    }
  }

  _appendModal () {
    let modal = document.createElement('div')
    modal.classList.add('hexo-modal')
    modal.setAttribute('data-fields--select-target', 'modal')
    modal.append(this._createModalBody());
    modal.append(this._createModalCover());

    this.parentTarget.append(modal);
  }

  _createModalBody () {
    let modalBody = document.createElement('section')
    modalBody.classList.add('hexo-modal__box')
    modalBody.classList.add('hexo-modal__box--no-min')
    // TODO: Move this to a translation/value
    modalBody.innerHTML = '<h2 class="heading heading-2 hexo-margin__bottom">Please select from the list below</h2>'

    let buttonList = document.createElement('div')
    buttonList.classList.add('hexo-select-list')
    buttonList.setAttribute('data-fields--select-target', 'options')

    this.options.forEach(option => {
      let el = document.createElement('button')
      el.type = 'button'
      el.classList.add('hexo-select-list__option')
      el.innerHTML = option.text
      el.dataset.value = option.value
      el.dataset.action = 'click->fields--select#toggleValue'

      if ( this.selectedOption == option.value ) {
        el.classList.add('selected')
      }

      buttonList.append(el)
    });

    modalBody.append(buttonList)

    return modalBody
  }

  _createModalCover () {
    let modalCover = document.createElement('div')
    modalCover.classList.add('hexo-cover')
    modalCover.setAttribute('data-fields--select-target', 'modalCover')
    modalCover.dataset.action = 'click->fields--select#close'

    return modalCover
  }

  _createSelected () {
    let selected = document.createElement('div')
    selected.classList.add('hexo-field__input')
    selected.classList.add('hexo-field__input--select')
    selected.innerHTML = this._selectedText()
    selected.setAttribute('data-fields--select-target', 'selected')

    return selected
  }

  _selectedText () {
    let text = ''
    this.options.forEach(option => {
      if ( this.selectedOption == option.value ) {
        text = option.text
        return
      }
    })
    return text
  }

  _selectedOption () {
    const selectedItem = this.selectTarget.querySelector('option[selected=selected]')
    return ( selectedItem ) ? selectedItem.value : this._selectFirst()
  }

  _selectFirst () {
    let first = this.selectTarget.querySelector('option:first-child')

    if (first) {
      first.setAttribute('selected', 'selected')
      return this._selectedOption()
    } else {
      return null
    }
  }
}
