import { Controller } from '@hotwired/stimulus';
import { getDataPeriod } from '../../application/javascripts/helpers/live_dashboard_helper';

export default class extends Controller {
  static targets = ['transactionsList'];

  async connect() {
    this.transactionsObserver = new MutationObserver(
      this._removeTransactions.bind(this),
    );
    this.transactionsObserver.observe(this.transactionsListTarget, {
      childList: true,
      attributes: true,
      subtree: true,
    });

    this._toggleEmptyMessage();
    this._removeTransactions();

    await this.refresh();
  }

  async refresh() {
    const dataPeriod = getDataPeriod();

    // make Ajax call here, inside the callback call:
    const result = await fetch(
      '/merchant/live_dashboard/broadcast_changes?data_period=' + dataPeriod,
      { method: 'GET' },
    );

    if (result.status === 204) {
      setTimeout(async () => await this.refresh(), 10000);
    } else {
      setTimeout(async () => await this.refresh(), 300000);
    }

    this._toggleEmptyMessage();
  }

  disconnect() {
    this.transactionsObserver.disconnect();
  }

  _toggleEmptyMessage() {
    const tableClassList = this.transactionsListTarget.classList

    if (this.transactionsListTarget.children.length < 1) {
      tableClassList.add('empty')
    } else {
      tableClassList.remove('empty')
    }
  }

  _removeTransactions() {
    const MAX_TRANSACTIONS = 7;

    const transactions = Array.from(this.transactionsListTarget.children);

    if (transactions.length > MAX_TRANSACTIONS) {
      const targetsForRemoval = transactions.slice(MAX_TRANSACTIONS);
      targetsForRemoval.forEach((target) => {
        target.remove();
      });
    }
  }
}
