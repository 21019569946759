import dayjs from 'dayjs';
const advancedFormat = require('dayjs/plugin/advancedFormat');
dayjs.extend(advancedFormat);

export const readParams = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const params = {
    data_period: 'daily',
  };

  for (const [key, value] of urlParams.entries()) {
    params[key] = value;
  }

  return params;
};

export const getDataPeriod = () => {
  const el = document.querySelector('input[name=data_period]:checked')
  if (el) { return el.value }
  return false
};

export const generateTransactionPeriod = (dataPeriod) => {
  let transactionsByPeriod = undefined;

  switch (dataPeriod) {
    case 'daily':
      transactionsByPeriod = new Array(24).fill(0, 0, 24);
      break;
    case 'weekly':
      transactionsByPeriod = new Array(7).fill(0, 0, 24);
      break;
    case 'monthly':
      transactionsByPeriod = new Array(dayjs().daysInMonth()).fill(
        0,
        0,
        dayjs().daysInMonth(),
      );
      break;
  }

  return transactionsByPeriod;
};

export const generateTransactionPeriodWithObject= (dataPeriod, value = 0) => {
  return generateTransactionPeriod(dataPeriod).map(
    (_item) => Object.assign({}, value)
  );
};

export const currencyToolTip = (currency) => {
  return {
    callbacks: {
      label: function (context) {
        let label = context.dataset.label || '';
        if (label) {
          label += ': ';
        }
        if (
          context.parsed.y !== null
        ) {
          label += new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency,
          }).format(context.parsed.y);
        }
        return label;
      },
    },
  }
}

export const percentageToolTip = () => {
  return {
    callbacks: {
      label: function (context) {
        let label = context.dataset.label || '';
        if (label) {
          label += ': ';
        }
        if (context.parsed.y !== null) {
          label += context.parsed.y;
          label += '%'
        }
        return label;
      },
    },
  };
}

export const percentageTicks = () => {
  return {
    callback: function (value, _index, _values) {
      return value + '%';
    },
  };
}

export const currencyTickScale = (currency) => {
  return {
    callback: function (value, _index, _values) {
      const formattedValue = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency,
        compactDisplay: 'short',
        notation: 'compact',
      }).format(value);
      return formattedValue;
    },
  }
}

export const baseChartConfig = (name, type) => {
  return {
    data: {},
    type: type,
    options: {
      responsive: true,
      aspectRatio: 1,
      onResize: (target, dimensions) => {
        const width = window.innerWidth
        const orientation = (screen.orientation || {}).type || screen.mozOrientation || screen.msOrientation
        const panelSize = target.ctx.canvas.parentNode.parentNode.dataset.panelSize
        const multiPanel = width > 700
        const landscape = (orientation && orientation.includes('landscape'))
        let ratio = 1

        if (multiPanel && (panelSize >= 3 || (width >= 830) && landscape)) {
          ratio = panelSize;
        }

        let aspectRatio = dimensions.width / (dimensions.width / ratio)

        if (['doughnut', 'pie'].includes(target.config.type)){
          aspectRatio += 0.025
        } else {
          aspectRatio += 0.3
        }

        target.options.aspectRatio = aspectRatio
      },
      interaction: {
        mode: 'index',
        intersect: false,
      },
      stacked: false,
      elements: {
        point: {
          radius: 0,
        },
      },
      plugins: {
        title: {
          align: 'start',
          display: true,
          text: name,
          color: 'rgb(38, 118, 188)',
          font: {
            size: 14,
            family: "'IBM Plex Sans', Arial, sans-serif",
          },
          padding: {
            bottom: 25,
          },
        },
        legend: {
          position: 'bottom',
          labels: {
            font: {
              size: 12,
              weight: 'bold',
            },
            usePointStyle: true,
          },
        },
        datalabels: {
          display: false,
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
        },
        y: {
          type: 'linear',
          display: true,
          position: 'left',
          grid: {
            drawOnChartArea: false,
            display: false,
          },
          min: 0,
          suggestedMax: 100,
        },
      },
    },
  };
}
