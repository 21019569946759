import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['parent', 'content', 'modal', 'modalCover', 'modalContent'];
  static values = {
    openOnCreate: Boolean,
    destroyOnClose: Boolean,
    small: Boolean
  }

  connect () {
    this.element[this.identifier] = this
    this._buildModal();

    if (this.hasOpenOnCreateValue && this.openOnCreateValue === true) {
      this.openModal()
    }
  }

  openModal (e) {
    if (e) {
      e.preventDefault();
    }

    this.modalTarget.classList.add('open')
    this.modalCoverTarget.classList.add('hexo-cover__show')
  }

  closeModal (e) {
    if (e) {
      e.preventDefault();
    }

    this.modalTarget.classList.remove('open')
    this.modalCoverTarget.classList.remove('hexo-cover__show')

    if (this.hasDestroyOnCloseValue && this.destroyOnCloseValue === true) {
      this.element.remove()
    }
  }

  _buildModal () {
    let modal = document.createElement('div')
    modal.classList.add('hexo-modal')
    modal.dataset.modalTarget = 'modal'

    let modalBox = document.createElement('section')
    modalBox.classList.add('hexo-modal__box')
    modalBox.dataset.modalTarget = 'modalContent'
    modalBox.innerHTML = this.contentTarget.innerHTML

    if (this.hasSmallValue && this.smallValue === true) {
      modalBox.classList.add('hexo-modal__box--no-min')
    }

    let modalCover = document.createElement('div')
    modalCover.classList.add('hexo-cover')
    modalCover.dataset.modalTarget = 'modalCover'
    modalCover.dataset.action = 'click->modal#closeModal'

    modal.append(modalCover)
    modal.append(modalBox)

    this.parentTarget.append(modal)
  }
}
