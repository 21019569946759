import { Controller } from '@hotwired/stimulus';
import Chart from 'chart.js/auto';
import stc from 'string-to-color';

import {
  generateTransactionPeriod,
  getDataPeriod,
  baseChartConfig,
  currencyToolTip,
  currencyTickScale
} from '../../application/javascripts/helpers/live_dashboard_helper';

export default class extends Controller {
  static targets = ['totalVolumePerGatewayGraph', 'volumePerGateway', 'filters'];

  connect() {
    this.dataPeriod = getDataPeriod();
    this.transactionsObserver = new MutationObserver(
      this._updateChart.bind(this),
    );
    this.transactionsObserver.observe(this.volumePerGatewayTarget.parentNode, {
      childList: true,
      attributes: true
    });

    this.transactionsObserver.observe(this.filtersTarget, {
      childList: true,
      attributes: true
    });

    if (this.volumePerGatewayTarget.children[0].value) {
      this.generateChart(this._generateChartConfig());
    }
  }

  disconnect() {
    this.transactionsObserver.disconnect();
  }

  generateChart(config) {
    this.chart = new Chart(this.totalVolumePerGatewayGraphTarget, config);
  }

  _chartData() {
    return JSON.parse(this.volumePerGatewayTarget.children[0].value).data
  }

  _chartLabels() {
    return JSON.parse(this.volumePerGatewayTarget.children[0].value).labels;
  }

  _generateChartConfig() {
    this.GATEWAYS = this._uniqueGateways();
    this._groupTransactionsByGateway();

    let config = baseChartConfig('Volume per Gateway', 'line');

    config.data = {
      labels: this._chartLabels(),
      datasets: this._generateDatasets(),
    }

    config.options.scales.y.ticks = currencyTickScale('EUR')
    config.options.plugins.tooltip = currencyToolTip('EUR')

    return config;
  }

  _uniqueGateways() {
    return this._chartData()
      .map((datum) => datum.gateway)
      .reduce(
        (gateways, key) => ({
          ...gateways,
          [key]: generateTransactionPeriod(this.dataPeriod),
        }),
        {},
      );
  }

  _getFilters () {
    let filters = null;

    if (this.filtersTarget.value) {
      filters = JSON.parse(this.filtersTarget.value)
    }

    return filters;
  }

  _getShopIds() {
    let shopIds = [];
    let filters = this._getFilters();

    if (filters) {
      shopIds = filters.shops.map((item) => parseInt(item))
    }

    return shopIds;
  }

  _groupTransactionsByGateway() {
    const data = this._chartData();
    const shopIds = this._getShopIds();

    data.forEach((datum) => {
      if (shopIds.includes(datum.shop)) {
        datum.dataset.forEach((item, index) => {
          if (this.GATEWAYS[datum.gateway]) {
            const total = (
              parseFloat(this.GATEWAYS[datum.gateway][index]) + parseFloat(item)
            ).toFixed(2);

            this.GATEWAYS[datum.gateway][index] = total;
          }
        });
      }
    });
  }

  _generateDatasets() {
    return Object.entries(this.GATEWAYS).map(
      ([gateway, gatewayTransactions]) => {
        const color = stc(gateway);

        return {
          label: gateway,
          data: gatewayTransactions,
          borderColor: color,
          backgroundColor: color,
          color: 'rgb(137, 139, 150)',
          tension: 0.1
        };
      },
    );
  }

  _updateChart() {
    if (
      this.hasVolumePerGatewayTarget &&
      this.volumePerGatewayTarget.children[0].value &&
      this.dataPeriod
    ) {
      this.GATEWAYS = this._uniqueGateways();
      this._groupTransactionsByGateway();

      Object.entries(this.GATEWAYS).map(([gateway, gatewayTransactions]) => {
        this._updateDataset(gateway, gatewayTransactions);
      });

      this._updateLabels();

      this.chart?.update();
    }
  }

  _updateDataset(key, data) {
    const dataset = this.chart.data.datasets.find((dataset) => {
      return dataset.label === key;
    });

    dataset.data = data;
  }

  _updateLabels() {
    if (this.chart) {
      this.chart.data.labels = this._chartLabels();
    }
  }
}
