import { Controller } from '@hotwired/stimulus';
import {
  csrfToken
} from '../../application/javascripts/helpers/api_requests_helper';

export default class extends Controller {
  static targets = ['name', 'value', 'errors']
  static fieldErrorClass = 'hexo-field--error'

  connect () {
    this.element[this.identifier] = this
  }

  async createSet () {
    if (this._validateFields()) {
      const response = await this._sendRequest()
      switch (response.status) {
        case 201:
          this._displayError('')
          this._refreshSets()
          this._closeModal()
          this._showNotice()
          this._clearFields()
          break
        case 422:
          this._displayError(response.text)
          break
      }
    }
  }

  async _refreshSets () {
    const sets = document.querySelector('*[data-controller="hex-protect--sets-render"]')

    if (sets) {
      await sets['hex-protect--sets-render'].refresh()
    }
  }
  _closeModal () {
    const modal = this.element.closest('*[data-controller="modal"]')

    if (modal) {
      modal['modal'].closeModal()
    }
  }

  _showNotice () {
    const alert = document.createElement('div')
    alert.classList.add('hexo-alert')
    alert.classList.add('hexo-alert--success')
    alert.dataset.controller = 'alert'
    alert.dataset.alertTimedValue = true
    alert.dataset.alertCloseableValue = true
    alert.dataset.alertTarget = 'parent'

    const wrapper = document.createElement('div')
    wrapper.classList.add('hexo-alert__wrapper')
    wrapper.dataset.alertTarget = 'wrapper'

    const text = document.createElement('div')
    text.classList.add('hexo-alert__text')
    text.innerHTML = 'Rule created'

    wrapper.append(text)
    alert.append(wrapper)

    this._alertContainer().append(alert)
  }

  _alertContainer () {
    return document.querySelector('.hexo-alerts-container')
  }

  _clearFields () {
    const fields = [
      this._nameField(),
      this._valueField()
    ]

    fields.forEach((field) => field.value = '')
  }

  async _sendRequest () {
    const formData = this._collectFormData()

    try {
      const result = await fetch('/merchant/sets', {
        headers: {
          'X-CSRF-Token': csrfToken()
        },
        method: 'POST',
        credentials: 'include',
        body: formData
      })

      const data = await result.json()

      return data
    } catch (e) {
      return {}
    }
  }

  _displayError (message) {
    if (this.hasErrorsTarget) {
      this.errorsTarget.innerHTML = ''

      if (message != '') {
        const error = document.createElement('div')
        error.classList.add('text--center')
        error.innerHTML = message

        this.errorsTarget.append(error)
      }
    }
  }

  _validateFields () {
    let status = true
    const fields = [
      {
        input: this._nameField(),
        field: this.nameTarget
      }, {
        input: this._valueField(),
        field: this.valueTarget
      }
    ]

    fields.forEach((f) => {
      if (f.input.value == '') {
        f.field.classList.add(this.constructor.fieldErrorClass)
        status = false
      } else {
        f.field.classList.remove(this.constructor.fieldErrorClass)
      }
    })

    return status
  }

  _collectFormData () {
    const creds = this._getCredentials()
    const form = document.createElement('form')

    const data = {
      'set[name]': this._nameField().value,
      'set[value]': this._valueField().value,
      'uid': creds.uuid,
      'level': creds.level
    }

    Object.entries(data).forEach(([key, value]) => {
      const input = document.createElement('input')
      input.type = 'hidden'
      input.name = key,
      input.value = value

      form.append(input)
    })

    return new FormData(form)
  }

  _nameField () {
    if (this.hasNameTarget) {
      return this.nameTarget.querySelector('input')
    } else {
      return {}
    }
  }

  _valueField () {
    if (this.hasValueTarget) {
      return this.valueTarget.querySelector('textarea')
    } else {
      return {}
    }
  }

  _getCredentials () {
    const filters = document.querySelector('*[data-controller="hex-protect--filters"]')

    if (filters) {
      return filters['hex-protect--filters'].currentDetails()
    } else {
      return {}
    }
  }
}
