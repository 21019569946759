import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static hideClass = 'hidden'

  static targets = [
    'dependantField',
    'section'
  ]

  static values = {
    showWhen: String
  }

  connect () {
    this._toggleVisibility()
  }

  change (e) {
    this._toggleVisibility()
  }

  _toggleVisibility () {
    if (this._fieldValueMatch()) {
      this._showSection()
    } else {
      this._hideSection()
    }
  }

  _showSection () {
    this.sectionTarget.classList.remove(this.constructor.hideClass)
  }

  _hideSection () {
    this.sectionTarget.classList.add(this.constructor.hideClass)
  }

  _fieldValueMatch () {
    switch (this.dependantFieldTarget.type) {
      case "checkbox":
        return this._checkboxValueMatch()
        break
      default:
        return this._defaultValueCheck()
    }
  }

  _defaultValueCheck () {
    return this.dependantFieldTarget.value === this.showWhenValue
  }

  _checkboxValueMatch () {
    return this.dependantFieldTarget.checked.toString() === this.showWhenValue.toLowerCase()
  }
}
