import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ 'emptyMessage' ]

  connect () {
    this.element[this.identifier] = this
    this.unreadNotifcationsCount = this._unreadNotificationsCount()
    this.refresh()
  }

  refresh () {
    this._toggleUnread()
    this._toggleEmptyMessage()
  }

  _toggleEmptyMessage () {
    if (!this._gotNotifications()) {
      this._showEmptyMessage()
    } else {
      this._hideEmptyMessage()
    }
  }

  _toggleUnread () {
    if (this._hasUnreadNotifications()) {
      if (this._unreadNotificationsCount() > this.unreadNotifcationsCount) {
        this._markUnread()
      }

      this.unreadNotifcationsCount = this._unreadNotificationsCount()
      this._markButtonUnread()
    } else {
      this._markButtonRead()
    }
  }

  _markUnread () {
    const buttonTarget = document.querySelector('*[data-header-target="notifications"]')

    if (buttonTarget) {
      const el = document.createElement('div')
      el.classList.add('hexo-actions__alert')
      el.setAttribute('data-controller', 'temporary-alert')
      el.innerHTML = 'New Notification'

      buttonTarget.append(el)
    }
  }

  _markButtonRead () {
    const icon = this._headerIcon()

    if (icon) {
      icon.classList.remove('hexo-actions__toggle--alert')
    }
  }

  _markButtonUnread () {
    const icon = this._headerIcon()

    if (icon) {
      icon.classList.add('hexo-actions__toggle--alert')
    }
  }

  _showEmptyMessage () {
    if (!this.hasEmptyMessageTarget) {
      const el = document.createElement('div')
      el.classList.add('text')
      el.classList.add('text--center')
      el.classList.add('text--bold')
      el.setAttribute('data-notification-list-target', 'emptyMessage')
      el.innerHTML = 'No notifications'

      this.element.append(el)
    }
  }

  _hideEmptyMessage () {
    if (this.hasEmptyMessageTarget) {
      this.emptyMessageTarget.remove()
    }
  }

  _gotNotifications () {
    const notifications = this.element.querySelectorAll('.hexo-notification')

    return notifications.length > 0
  }

  _hasUnreadNotifications () {
    return this._unreadNotificationsCount() > 0
  }

  _unreadNotificationsCount () {
    return this.element.querySelectorAll('.hexo-notification--unread').length
  }

  _headerIcon () {
    return document.querySelector('*[data-header-target="notifications"]')
  }
}
