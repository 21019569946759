import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['parent', 'input', 'toggleButton']

  connect () {
    this.parentTarget.append(this._buildToggle())
  }

  toggleShow (e) {
    e.preventDefault()

    if (this.inputTarget.type == 'password') {
      this.toggleButtonTarget.classList.add('active')
      this.inputTarget.type = 'text'
    } else {
      this.toggleButtonTarget.classList.remove('active')
      this.inputTarget.type = 'password'
    }
  }

  _buildToggle () {
    let icon = document.createElement('span')
    icon.classList.add('hexo-icon')
    icon.classList.add('hexo-icon--show')

    let toggle = document.createElement('button')
    toggle.type = "button"
    toggle.classList.add('hexo-field__show')
    toggle.dataset.action = 'click->show-password#toggleShow'
    toggle.dataset.showPasswordTarget = 'toggleButton'
    toggle.append(icon)

    return toggle;
  }

}
