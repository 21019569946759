import { tsMethodSignature } from '@babel/types';
import { Controller } from '@hotwired/stimulus';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { baseChartConfig } from '../../application/javascripts/helpers/live_dashboard_helper';

Chart.register(ChartDataLabels)

export default class extends Controller {
  static targets = ['totalTransactionStatusCountGraph', 'filters'];
  static label = 'Current Period'

  connect() {

    if (document !== null) {
      this.transactionsObserver = new MutationObserver(
        this._updateChart.bind(this),
      );
      this.transactionsObserver.observe(this._target().parentNode.parentNode, {
        childList: true,
      });

      this.transactionsObserver.observe(this.filtersTarget, {
        childList: true,
        attributes: true
      });

      if (this._target().value) {
        this.generateChart(this._chartConfig());
      }
    }
  }

  generateChart(config) {
    this.chart = new Chart(this.totalTransactionStatusCountGraphTarget, config)
  }

  _chartData() {
    if (this._target !== null)
    return JSON.parse(this._target().value).data;
  }

  _target() {
    return document.querySelector('[name=transaction_status_count]')
  }

  _getFilters () {
    let filters = null;

    if (this.filtersTarget.value) {
      filters = JSON.parse(this.filtersTarget.value)
    }

    return filters;
  }

  _getShopIds() {
    let shopIds = [];
    let filters = this._getFilters();

    if (filters) {
      shopIds = filters.shops.map((item) => parseInt(item))
    }

    return shopIds;
  }

  _generateDatasets() {
    this.STATUS_TOTALS = {
      success: 0,
      pending: 0,
      failed: 0
    }

    const shopIds = this._getShopIds();

    this._chartData().forEach((datum) => {
      if (shopIds.includes(datum.shop)) {
        datum.dataset.forEach((value) => {
          this.STATUS_TOTALS[datum.type] += value;
        })
      }
    });
  }

  _chartDataset() {
    this._generateDatasets();

    return [
      this.STATUS_TOTALS.success,
      this.STATUS_TOTALS.failed,
      this.STATUS_TOTALS.pending
    ]
  }

  _updateChart() {
    if (
      this._target() &&
      this._target().value
    ) {
      this._updateDataset(this.constructor.label, this._chartDataset())
      this.chart?.update();
    }
  }

  _updateDataset(key, data) {
    const dataset = this.chart.data.datasets.find((dataset) => {
      return dataset.label === key;
    });

    dataset.data = data;
  }
  _chartConfig() {
    this._generateDatasets();

    let config = baseChartConfig('Transaction Status Totals', 'doughnut');

    config.data = {
      labels: [
        'Successful',
        'Declined',
        'Pending'
      ],
      datasets: [
        {
          label: this.constructor.label,
          data: this._chartDataset(),
          backgroundColor: [
            'rgba(90, 190, 77, 0.7)',
            'rgba(218, 36, 78, 0.7)',
            'rgba(228, 113, 50, 0.7)'
          ],
          hoverOffset: 4
        }
      ]
    }

    config.options.scales = null
    config.options.plugins.datalabels = {
      formatter: (value, ctx) => {
        let datasets = ctx.chart.data.datasets;
        let percentage = 0

        if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
          let hiddenDatasets = []

          ctx.chart.legend.legendItems.forEach((item, index) => {
            if (item.hidden) {
              hiddenDatasets.push(index)
            }
          })

           let sum = datasets[0].data.reduce((a, b, idx) => {
             let value = a;
             if (!hiddenDatasets.includes(idx)) {
              value += b
             }
             return value
           }, 0);

           let percentage = Math.round((value / sum) * 100) + '%';
           return percentage;
         } else {
           return percentage;
         }
      },
      color: '#fff',
      font: {
        weight: 'bold',
        family: "'IBM Plex Sans', Arial, sans-serif",
      }
    }

    return config;
  }
}
