import { Controller } from '@hotwired/stimulus';
import {
  csrfToken
} from '../../application/javascripts/helpers/api_requests_helper';

export default class extends Controller {
  static targets = ['conditions', 'errors']
  static values = {
    currencies: String
  }
  static defaultCurrencies = ["EUR", "GBP", "USD"]
  static ruleRenderTarget = 'hex-protect--rules-render'
  static modalTarget = 'modal'
  static filtersTarget = 'hex-protect--filters'

  connect () {
    this.element[this.identifier] =  this
    this.addCondition()
    this.currencies = this._formatCurrencies()
  }

  addCondition () {
    if (this.hasConditionsTarget) {
      this.conditionsTarget.append(this._buildCondition())
    }
  }

  async sendRule () {
    const conditions = this._validateConditions()

    if (this._validateAlias() && conditions.valid) {
      const response = await this._sendCreateRequest(this._getAlias(), conditions.data, this._getAction())

      switch (response.status) {
        case 201:
          this._displayError('')
          await this._refreshRules()
          this._closeModal()
          this._showNotice(response.text)
          this._resetForm()
          break;
        case 422:
          this._displayError(response.text)
          break;
      }
    }
  }

  supportedCurrencies () {
    return this.currencies
  }

  _formatCurrencies () {
    if (this.hasCurrenciesValue) {
      return JSON.parse(this.currenciesValue)
    } else {
      return this.constructor.defaultCurrencies
    }
  }

  _resetForm () {
    this.element.querySelectorAll('input[type="text"]').forEach((i) => {
      i.value = ''
    })
    this._resetSelects()
    this._resetConditions()
  }

  _resetSelects () {
    this._findSelects().forEach((el) => el['fields--select'].reset())
  }

  _findSelects () {
    return this.element.querySelectorAll('*[data-select-element] *[data-controller="fields--select"]')
  }

  _resetConditions () {
    if (this.hasConditionsTarget) {
      this.conditionsTarget.innerHTML = ''
      this.addCondition()
    }
  }

  _displayError (message) {
    if (this.hasErrorsTarget) {
      this.errorsTarget.innerHTML = ''

      if (message != '') {
        const error = document.createElement('div')
        error.classList.add('hexo-form-error')
        error.classList.add('text--center')
        error.classList.add('hexo-margin__top')
        error.innerHTML = message

        this.errorsTarget.append(error)
      }
    }
  }

  _validateConditions () {
    const conditions = [...this._collectConditions()]
    const allConditions = conditions.map((el) => this._getConditionFor(el) )

    let formattedConditions = []
    let validConditions = true

    allConditions.forEach((c) => {
      if (!c.valid) {
        validConditions = false
      }

      formattedConditions.push(c.data)
    })

    return { valid: validConditions, data: formattedConditions.join(' AND ') }
  }

  async _sendCreateRequest (alias, conditions, action) {
    const formData = this._getFormData(alias, conditions, action)

    try {
      const result = await fetch('/merchant/rules', {
        headers: {
          'X-CSRF-Token': csrfToken()
        },
        method: 'POST',
        credentials: 'include',
        body: formData
      })

      const data = await result.json()

      return data
    } catch (e) {
      return undefined
    }
  }

  _validateAlias () {
    const alias = this._aliasField()
    const field = alias.closest('.hexo-field')
    let result = alias.value != ''

    if (result) {
      field.classList.remove('hexo-field--error')
    } else {
      field.classList.add('hexo-field--error')
    }

    return result
  }

  _getFormData (alias, conditions, action) {
    const creds = this._getCredentials()
    const form = document.createElement('form')

    const data = [
      {
        key: 'rule[alias_name]',
        value: alias
      }, {
        key: 'rule[condition]',
        value: conditions
      }, {
        key: 'rule[action]',
        value: action
      }, {
        key: 'uid',
        value: creds.uuid
      }, {
        key: 'level',
        value: creds.level
      }
    ]

    data.forEach((field) => {
      const input = document.createElement('input')
      input.type = 'hidden'
      input.name = field.key,
      input.value = field.value

      form.append(input)
    })

    return new FormData(form)
  }

  _getAlias () {
    return this._aliasField().value
  }

  _aliasField () {
    return this.element.querySelector('input[name="alias_name"]')
  }

  _getAction () {
    return this.element.querySelector('input[name="action"]').value
  }

  _getCredentials () {
    const filters = document.querySelector(this._targetString(this.constructor.filtersTarget))

    if (filters) {
      return filters[this.constructor.filtersTarget].currentDetails()
    } else {
      return {}
    }
  }

  _closeModal () {
    const modal = this.element.closest(this._targetString(this.constructor.modalTarget))

    if (modal) {
      modal[this.constructor.modalTarget].closeModal()
    }
  }

  async _refreshRules () {
    const rules = document.querySelector(this._targetString(this.constructor.rulesRenderTarget))

    if (rules) {
      await rules[this.constructor.rulesRenderTarget].refresh()
    }
  }

  _targetString (target) {
    return '*[data-controller="' + target + '"]'
  }

  _showNotice (_response) {
    const alert = document.createElement('div')
    alert.classList.add('hexo-alert')
    alert.classList.add('hexo-alert--success')
    alert.dataset.controller = 'alert'
    alert.dataset.alertTimedValue = true
    alert.dataset.alertCloseableValue = true
    alert.dataset.alertTarget = 'parent'

    const wrapper = document.createElement('div')
    wrapper.classList.add('hexo-alert__wrapper')
    wrapper.dataset.alertTarget = 'wrapper'

    const text = document.createElement('div')
    text.classList.add('hexo-alert__text')
    text.innerHTML = 'Rule created'

    wrapper.append(text)
    alert.append(wrapper)

    this._alertContainer().append(alert)
  }

  _alertContainer () {
    return document.querySelector('.hexo-alerts-container')
  }

  _buildCondition (deletable) {
    const wrapper = document.createElement('div')
    wrapper.classList.add('hexo-columns')
    wrapper.dataset.controller = 'hex-protect--rule-condition'
    wrapper.setAttribute('data-hex-protect--rule-condition-delete-value', this._canDeleteCondition())

    return wrapper
  }

  _canDeleteCondition () {
    if (this.hasConditionsTarget) {
      return this._collectConditions().length > 0
    }

    return false
  }

  _collectConditions () {
    return this.conditionsTarget.querySelectorAll('div[data-controller="hex-protect--rule-condition"]')
  }

  _getConditionFor (condition) {
    return condition['hex-protect--rule-condition'].buildData()
  }
}
