import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['button']
  static values = {
    noticeId: String
  }

  connect () {
    if (this.hasButtonTarget) {
      const button = this._buildButton()
      const parent = this.buttonTarget.parentElement

      parent.prepend(button)
      this.buttonTarget.style = 'display:none'
    }
  }

  dismiss () {
    this._hideNotice()
    if (this.hasNoticeIdValue) {
      this._saveDismissed()
    }
  }

  _saveDismissed () {
    fetch('/releases/' + this.noticeIdValue + '/dismiss', {
      headers: {
        'accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': this._crsfToken(),
      },
      method: 'POST',
      credentials: 'include'
    })
  }

  _hideNotice () {
    this.element.classList.add('hidden');
    setTimeout(() => this.element.remove(), 1000)
  }

  _crsfToken () {
    const csrfToken = document.querySelector('meta[name="csrf-token"]')

    if (csrfToken) {
      return csrfToken.getAttribute('content')
    } else {
      return ''
    }
  }

  _buildButton () {
    let button = document.createElement('button')
    button.type = 'button'
    button.classList = this.buttonTarget.classList
    button.innerHTML = this.buttonTarget.innerHTML
    button.dataset.action = 'click->release-notice#dismiss'

    return button
  }
}
