import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'parent',
    'field',
    'select',
    'modal',
    'modalCover',
    'options',
    'submittedOptions',
    'selected',
    'searchField',
    'hiddenField'
  ]

  connect () {
    this.options = this._allOptions()
    this.selectedOptions = this._preselectedOptions()

    this._appendModal()
    this._appendOptions()
    this.fieldTarget.append(this._createSelected())
    this.fieldTarget.append(this._hiddenOptions())
    this.selectTarget.remove()
    this._updateSelectedText()


    if (this.options.length > 10) {
      this._enableSearch()
    }
  }

  open (e) {
    e.preventDefault()
    this._openModal()
    if (this.options.length > 10) {
      this.searchFieldTarget.focus()
    }
  }

  close (e) {
    e.preventDefault()
    this._closeModal()
  }

  toggleValue (e) {
    e.preventDefault()

    const t = e.target
    t.classList.toggle('selected')

    t.buttonList
  }

  search (e) {
    let fieldText = this.searchFieldTarget.value.toLowerCase().split(' ')

    this.optionsTarget.querySelectorAll('button').forEach(o => {
      let optionText = o.innerHTML.toLowerCase()
      if (fieldText.length > 0) {
        let finds = 0
        fieldText.forEach(i => {
          if ( optionText.includes(i) ) {
            finds++
          }
        })

        if (finds > 0) {
          o.classList.remove('hidden')
        } else {
          o.classList.add('hidden')
        }
      } else {
        o.classList.remove('hidden')
      }
    })

    if (this.optionsTarget.querySelectorAll('button:not(.hidden)').length == 0) {
      this.optionsTarget.classList.add('empty')
    } else {
      this.optionsTarget.classList.remove('empty')
    }
  }

  _hiddenOptions () {
    const hiddenField = document.createElement('input')

   const attrs = [...this.selectTarget.attributes]
   attrs.forEach((a) => hiddenField.setAttribute(a.nodeName, a.nodeValue))
    hiddenField.name = ''
    hiddenField.type = 'hidden'
    hiddenField.setAttribute('data-fields--multi-select-target', 'hiddenField')

    return hiddenField
  }

  _setSelected () {
    this.hiddenFieldTarget.value = JSON.stringify(this.selectedOptions)
  }

  _allOptions () {
    const options = this.selectTarget.querySelectorAll('option')
    let formattedOptions = []

    options.forEach(o => {
      formattedOptions.push(
        {
          value: o.value,
          text: o.text
        }
      )
    })

    return formattedOptions
  }

  _preselectedOptions () {
    const selectedOptions = this.selectTarget.querySelectorAll('option[selected=selected]')
    let selected = []

    if (selectedOptions && selectedOptions.length > 0) {
      selectedOptions.forEach(o => selected.push(o.value))
    }

    return selected
  }

  _appendModal () {
    this.parentTarget.append(this._buildModal());
  }

  _openModal () {
    this.modalTarget.classList.add('open')
    this.modalCoverTarget.classList.add('hexo-cover__show')
  }

  _closeModal () {
    this.selectedOptions = []

    const options = this.optionsTarget.querySelectorAll('.selected')
    options.forEach(o => this.selectedOptions.push(o.dataset.value))
    this._updateSelectedText()
    this._setSelected()

    this.modalTarget.classList.remove('open')
    this.modalCoverTarget.classList.remove('hexo-cover__show')
  }

  _appendOptions () {
    let options = document.createElement('div')
    options.setAttribute('data-fields--multi-select-target', 'submittedOptions')
    options.setAttribute('data-name', this.selectTarget.name)

    this.parentTarget.append(options)
  }

  _buildModal () {
    let modal = document.createElement('div')
    modal.classList.add('hexo-modal')
    modal.setAttribute(
      'data-fields--multi-select-target',
      'modal'
    )

    modal.append(this._buildModalCover())
    modal.append(this._buildModalBody())

    return modal
  }

  _buildModalCover () {
    let modalCover = document.createElement('div')
    modalCover.classList.add('hexo-cover')
    modalCover.setAttribute(
      'data-fields--multi-select-target',
      'modalCover'
    )
    modalCover.dataset.action = 'click->fields--multi-select#close'

    return modalCover
  }

  _buildModalBody () {
    let modalBody = document.createElement('section')
    modalBody.classList.add('hexo-modal__box')
    modalBody.classList.add('hexo-modal__box--no-min')
    // TODO: Move this to a translation/value
    modalBody.innerHTML = '<h2 class="heading heading-2 hexo-margin__bottom">Please select from the list below</h2>'

    let buttonList = document.createElement('div')
    buttonList.classList.add('hexo-select-list')
    buttonList.setAttribute(
      'data-fields--multi-select-target',
      'options'
    )

    this.options.forEach(e => {
      let el = document.createElement('button')
      el.type = 'button'
      el.classList.add('hexo-select-list__option')
      el.innerHTML = e.text
      el.dataset.value = e.value
      el.dataset.action = 'click->fields--multi-select#toggleValue'

      if ( this.selectedOptions.includes(e.value) ) {
        el.classList.add('selected')
      }

      buttonList.append(el)
    });

    modalBody.append(buttonList)

    return modalBody
  }

  _createSelected () {
    let selected = document.createElement('div')
    selected.classList.add('hexo-field__input')
    selected.classList.add('hexo-field__input--select')
    selected.innerHTML = this._selectedText()
    selected.setAttribute('data-fields--multi-select-target', 'selected')

    return selected
  }

  _updateSelectedText () {
    this.selectedTarget.innerHTML = this._selectedText()

    this.submittedOptionsTarget.innerHTML = ''
    this.selectedOptions.forEach(o => {
      let option = document.createElement('input')
      option.type = 'hidden'
      option.value = o
      option.name = this.submittedOptionsTarget.dataset.name

      this.submittedOptionsTarget.append(option)
    })
  }

  _selectedText () {
    return this.selectedOptions.length + " selected"
  }

  _enableSearch () {
    let searchField = document.createElement('input')
    searchField.type = 'text'
    searchField.classList.add('hexo-field__input')
    searchField.dataset.action = 'input->fields--multi-select#search'
    searchField.setAttribute('data-fields--multi-select-target', 'searchField')
    searchField.placeholder = 'Filter options...'

    let searchHolder = document.createElement('div')
    searchHolder.classList.add('hexo-field')

    let searchEl = document.createElement('div')
    searchEl.classList.add('hexo-select-list__filter')

    searchHolder.append(searchField)
    searchEl.append(searchHolder)
    this.optionsTarget.prepend(searchEl)
    this.optionsTarget.classList.add('hexo-select-list--searchable')
  }
}
