import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['filtersTray', 'filter']
  static openClass = 'filters__tray--open'

  connect () {
    this.transactionsObserver = new MutationObserver(
      this._applyFilters.bind(this),
    );

    this._filters().forEach((filter) => {
      this.transactionsObserver.observe(
        filter,
        {
          attributes: true,
        },
      );
    });

    this._applyFilters();
  }

  apply () {
    this._applyFilters()
  }

  toggleFilters () {
    this.filtersTrayTarget.classList.toggle(this.constructor.openClass)
  }

  closeFilters () {
    this.filtersTrayTarget.classList.toggle(this.constructor.openClass)
  }

  _filters () {
    let filters = []
    if (this.hasFiltersTrayTarget) {
      filters = this.filterTargets
    }
    return filters;
  }

  _applyFilters () {
    let dashboardFilters = {
      shops: []
    }

    this._filters().forEach((filter) => {
      if (![null, ''].includes(filter.value)) {
        dashboardFilters[filter.name] = JSON.parse(filter.value)
      }
    })

    document.querySelectorAll('input[name=filters]').forEach((obj) => {
      obj.value = JSON.stringify(dashboardFilters);
    })
  }
}
