import { Controller } from '@hotwired/stimulus';
import { easepick, AmpPlugin } from '@easepick/bundle';

export default class extends Controller {
  static targets = [
    'parent',
    'field',
    'input',
    'hiddenInput',
    'modalCover',
    'modal'
  ];

  static values = {
    title: String,
    setButton: String,
    clearButton: String
  }

  connect () {
    this.parentTarget.append(this._buildModal())
    this._setPicker()
    this.inputTarget.setAttribute('readonly', 'readonly')
  }

  open (e) {
    e.preventDefault()
    this.modalTarget.classList.add('open')
    this.modalCoverTarget.classList.add('hexo-cover__show')
  }

  close (e) {
    e.preventDefault()
    this.modalTarget.classList.remove('open')
    this.modalCoverTarget.classList.remove('hexo-cover__show')
    this.inputTarget.value = this.hiddenInputTarget.value
  }

  clear (e) {
    this.hiddenInputTarget.value = ''
    this.inputTarget.value = ''
    this.close(e)
  }

  _setPicker () {
    this.picker = new easepick.create({
      element: this.hiddenInputTarget,
      css: [
        'https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.0/dist/index.css',
        '/easepick.css'
      ],
      zIndex: 10,
      inline: true,
      AmpPlugin: {
          dropdown: {
              months: true,
              years: true
          }
      },
      plugins: [
          AmpPlugin
      ]
    });
  }

  _buildModal () {
    let modal = document.createElement('div')
    modal.classList.add('hexo-modal')
    modal.setAttribute('data-fields--datepicker-target', 'modal')
    modal.append(this._buildModalBody())
    modal.append(this._buildModalCover())

    return modal
  }

  _buildModalCover () {
    let modalCover = document.createElement('div')
    modalCover.classList.add('hexo-cover')
    modalCover.dataset.action = 'click->fields--datepicker#close'
    modalCover.setAttribute('data-fields--datepicker-target', 'modalCover')

    return modalCover
  }

  _buildModalBody () {
    let modalBody = document.createElement('section')
    modalBody.classList.add('hexo-modal__box')
    modalBody.classList.add('hexo-modal__box--no-min')
    modalBody.classList.add('text--center')
    modalBody.append(this._buildDatepicker())
    modalBody.append(this._buildButtonGroup())

    if (this.titleValue) {
      modalBody.prepend(this._buildTitle())
    }

    return modalBody
  }

  _buildTitle () {
    let title = document.createElement('h2')
    title.classList.add('heading')
    title.classList.add('heading-2')
    title.innerHTML = this.titleValue

    return title
  }

  _buildDatepicker () {
    let datepicker = document.createElement('input')
    datepicker.type = 'hidden'
    datepicker.value = this.inputTarget.value
    datepicker.setAttribute('data-fields--datepicker-target', 'hiddenInput')

    return datepicker
  }

  _buildButtonGroup () {
    let buttonGroup = document.createElement('div')
    buttonGroup.classList.add('hexo-btn-group')
    buttonGroup.classList.add('hexo-btn-group__between')
    buttonGroup.append(this._buildClearButton())
    buttonGroup.append(this._buildButton())

    return buttonGroup
  }

  _buildButton () {
    let button = document.createElement('button')
    button.type = 'button'
    button.classList.add('hexo-btn-default')
    button.classList.add('hexo-btn-default__primary')
    button.dataset.action = 'click->fields--datepicker#close'
    button.innerHTML = this._setButtonText()

    return button
  }

  _buildClearButton () {
    let button = document.createElement('button')
    button.type = 'button'
    button.classList.add('hexo-btn-default')
    button.classList.add('hexo-btn-default__cancel')
    button.dataset.action = 'click->fields--datepicker#clear'
    button.innerHTML = this._clearButtonText();

    return button
  }

  _clearButtonText () {
    return (this.clearButtonValue) ? this.clearButtonValue : 'Clear'
  }

  _setButtonText () {
    return (this.setButtonValue) ? this.setButtonValue : 'Set1'
  }
}
