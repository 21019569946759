import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['option', 'panels']
  static activeClass = 'hexo-tabs--active'
  static activeElementClass = 'active'

  connect () {
    this.element.classList.add(this.constructor.activeClass)
    this._openPanel(this._firstOption())
  }

  open (e) {
    this._openPanel(e.target)
  }

  _openPanel (button) {
    const value = button.dataset.value

    if (this.currentPanel !== value) {
      this._closeAllPanels()

      button.classList.add(this.constructor.activeElementClass)
      const panel = this._findPanel(button.dataset.value)
      panel.classList.add(this.constructor.activeElementClass)

      this.currentPanel = value
    }
  }

  _closeAllPanels () {
    this._allActiveElements().forEach((o) => {
      o.classList.remove(this.constructor.activeElementClass)
    })
  }

  _firstOption () {
    return this.optionTargets[0]
  }

  _findPanel (value) {
    return this.panelsTarget.querySelector('*[data-panel=' + value + ']')
  }

  _allActiveElements() {
    return this._activeOptions().concat(this._activePanels())
  }

  _activeOptions () {
    return this.optionTargets.filter((o) => this._notActive(o))
  }

  _activePanels () {
    return this._allPanels().filter((p) => this._notActive(p))
  }

  _allPanels () {
    return Array.prototype.slice.call(
      this.panelsTarget.querySelectorAll('*[data-panel]')
    )
  }

  _notActive (el) {
    return el.classList.contains(this.constructor.activeElementClass)
  }
}
